<div class="container pb-2 mt-2">
  <div *ngIf="!isPipelineOverviewInstance(allInstances[0])" class="d-flex gap-1 mt-2 flex-wrap">
    <button (click)="onClearFilters()" class="badge border-0 bg-primary">Remove filters</button>
    <button (click)="onFilterAll()" class="badge border-0 bg-primary">All tags</button>
    <button (click)="onFilterNone()" class="badge border-0 bg-primary">No tags</button>
    <div
      (click)="onFilterTable(tag)"
      *ngFor="let tag of allTags"
      [ngStyle]="{
        'background-color': isTagUsedForFilter[tag.uid] ? tag.color : 'var(--bs-gray)',
        border: 'none',
        color: isTagUsedForFilter[tag.uid] ? tag.contrastColor : 'white',
        height: '28px',
      }"
      class="badge align-content-center ms-1 schema-tag cursor-pointer"
      id="filter-tag-{{ tag.name }}"
      role="none"
    >
      {{ tag.name }}
    </div>
  </div>
  <div *ngIf="isPipelineOverviewInstance(allInstances[0])" class="d-flex gap-1 mt-2 flex-wrap">
    <button
      (click)="onFilterNoPickedResult()"
      [ngStyle]="{ 'background-color': filterOnNoPickedResult ? 'var(--bs-primary)' : 'var(--bs-gray)', height: '28px' }"
      class="badge border-0"
    >
      No picked result
    </button>
    <button
      (click)="onFilterOnOutdatedResult()"
      [ngStyle]="{ 'background-color': filterOnOutdatedResult ? 'var(--bs-primary)' : 'var(--bs-gray)', height: '28px' }"
      class="badge border-0"
    >
      Outdated picked result
    </button>
  </div>

  <div class="d-flex py-2 gap-1">
    <button [disabled]="true" class="btn btn-secondary text-nowrap">{{ selectedInstances.length }} selected</button>

    <input
      (ngModelChange)="debouncedSearch($event)"
      [(ngModel)]="searchTerm"
      class="form-control"
      id="search-input"
      placeholder="Search"
      type="text"
    />

    <button (click)="this.searchTerm = ''; search()" *ngIf="searchTerm.length" class="btn btn-secondary" type="button">
      <span class="bi bi-x"></span>
    </button>

    <button
      *ngIf="!isPipelineOverviewInstance(allInstances[0])"
      [disabled]="selectedInstances.length === 0"
      [matMenuTriggerFor]="tagMenu"
      class="btn btn-secondary"
      ngbPopover="Add tag to selected"
      triggers="hover"
      type="button"
    >
      <span class="bi bi-tag"></span>
    </button>
    <mat-menu #tagMenu="matMenu" (closed)="newTagName = ''; filterTags('')">
      <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" class="d-flex mb-1 mx-1" role="none">
        <input
          (ngModelChange)="filterTags($event)"
          [(ngModel)]="newTagName"
          [maxLength]="25"
          class="form-control"
          placeholder="Search/Create tag"
          type="text"
        />
        <input [(ngModel)]="newTagColor" class="cursor-pointer my-auto mx-1" type="color" />
        <button (click)="onAddTag()" [disabled]="!newTagName" class="btn btn-primary"><i class="bi-plus"></i></button>
      </div>
      <div *ngFor="let tag of filteredTags" class="mb-1 ms-1">
        <button
          (click)="onTagSelected({ tag: tag, isAdded: true }, undefined, true)"
          [ngStyle]="{ 'background-color': tag.color, color: tag.contrastColor, height: '28px' }"
          class="badge border-0"
        >
          {{ tag.name }}
        </button>
      </div>
    </mat-menu>
    <button
      (click)="onBulkCopyInstance()"
      *ngIf="allInstances.length > 0 && isDataModelListInstance(allInstances[0])"
      [disabled]="selectedInstances.length === 0"
      class="btn btn-secondary"
      ngbPopover="Copy selected"
      triggers="hover"
      type="button"
    >
      <span class="bi bi-copy"></span>
    </button>
    <button
      (click)="onRunMultiplePipelines()"
      *ngIf="allInstances.length > 0 && isPipelineOverviewInstance(allInstances[0])"
      [disabled]="selectedInstances.length === 0"
      class="btn btn-secondary"
      ngbPopover="Run all selected"
      triggers="hover"
      type="button"
    >
      <span class="bi bi-lightning"></span>
    </button>
    <button
      (click)="onDownloadPickedMedia()"
      *ngIf="allInstances.length > 0 && isPipelineOverviewInstance(allInstances[0])"
      [disabled]="selectedInstances.length === 0"
      class="btn btn-secondary"
      ngbPopover="Download media of all selected"
      triggers="hover"
      type="button"
    >
      <span class="bi bi-download"></span>
    </button>
    <button
      (click)="onPageChange(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="btn btn-secondary"
      ngbPopover="Previous page"
      triggers="hover"
    >
      <span class="bi bi-arrow-left"></span>
    </button>
    <span class="align-content-center">{{ currentPage }}/{{ maxPages }}</span>
    <button
      (click)="onPageChange(currentPage + 1)"
      [disabled]="currentPage === maxPages"
      class="btn btn-secondary"
      ngbPopover="Next page"
      triggers="hover"
    >
      <span class="bi bi-arrow-right"></span>
    </button>
    <select (change)="onChangePageSize()" [(ngModel)]="pageSize" class="form-select" id="paginated-size-select" style="max-width: 100px">
      <option *ngFor="let size of ['25', '50', '75', '100', 'all']" [selected]="size === pageSize" [value]="size">{{ size }}</option>
    </select>
  </div>
  <table
    (matSortChange)="sort($event)"
    [matSortActive]="this.sortingPreferences[this.listType].active"
    [matSortDirection]="this.sortingPreferences[this.listType].direction"
    class="table table-striped table-hover"
    matSort
  >
    <thead>
      <tr>
        <th>
          <input
            (change)="onSelectAllInstances()"
            [checked]="selectedInstances.length === filteredInstances.length"
            class="form-check-input"
            type="checkbox"
          />
        </th>
        <!-- column for non dataInstance and pipelineOverview list -->
        <th *ngIf="!isDataInstance(allInstances[0]) && !isPipelineOverviewInstance(allInstances[0])" mat-sort-header="name" scope="col">
          Name
        </th>

        <!-- columns for media-list -->
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" mat-sort-header="alt" scope="col">Alt text</th>
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" scope="col" style="text-align: center">Preview</th>
        <th *ngIf="isGeneratedFileMeta(allInstances[0])" mat-sort-header="note" scope="col">Note</th>

        <!-- columns for resource-list -->
        <th *ngFor="let field of fieldsToDisplay" mat-sort-header="{{ field.fieldId }}" scope="col">{{ field.name }}</th>
        <th *ngIf="isDataInstance(allInstances[0]) && fieldsToDisplay.length === 0" mat-sort-header="enumInstanceName" scope="col">Name</th>
        <th *ngIf="isDataInstance(allInstances[0]) && fieldsToDisplay.length === 0" mat-sort-header="enumInstanceStructType" scope="col">
          Type
        </th>

        <!-- column for variable and dataModel list -->
        <th *ngIf="isVariable(allInstances[0]) || isDataModelListInstance(allInstances[0])" mat-sort-header="description" scope="col">
          Description
        </th>

        <!-- columns for variable-list -->
        <th *ngIf="isVariable(allInstances[0])" mat-sort-header="playthroughStatistic" scope="col">Is play through statistic</th>

        <!-- column for non dataModel and pipelineOverview lists -->
        <th
          *ngIf="!isSchemaListInstance(allInstances[0]) && !isPipelineOverviewInstance(allInstances[0])"
          mat-sort-header="modified"
          scope="col"
        >
          Modified
        </th>

        <!-- column for all the lists except pipelineOverview -->
        <th *ngIf="!isPipelineOverviewInstance(allInstances[0])" scope="col">Tags</th>
        <th *ngIf="!isPipelineOverviewInstance(allInstances[0])" scope="col" style="width: 240px">Actions</th>

        <th *ngIf="isPipelineOverviewInstance(allInstances[0])" mat-sort-header="name" scope="col">Instance</th>
        <th *ngIf="isPipelineOverviewInstance(allInstances[0])" scope="col">Picked result</th>
        <th *ngIf="isPipelineOverviewInstance(allInstances[0])" scope="col">Results</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let instance of paginatedInstances">
        <td class="align-content-center">
          <input
            (change)="onSelectInstance(instance)"
            [checked]="selectedInstances.includes(instance)"
            class="form-check-input"
            type="checkbox"
          />
        </td>
        <!-- columns for media-list -->
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center">
          <div
            (click)="updateFileName = instance.name; fileMetaOperations[instance.uid].editingName = true"
            *ngIf="!fileMetaOperations[instance.uid].editingName"
            role="none"
          >
            {{ instance.name }}
          </div>
          <input
            (change)="onUpdateFileName(instance.uid)"
            *ngIf="fileMetaOperations[instance.uid].editingName"
            [(ngModel)]="updateFileName"
            class="form-control"
            style="max-width: 250px"
            type="text"
          />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center">
          <div
            (click)="updateFileAlt = instance.alt; fileMetaOperations[instance.uid].editingAlt = true"
            *ngIf="!fileMetaOperations[instance.uid].editingAlt"
            role="none"
          >
            {{ instance.alt }}
          </div>
          <input
            (change)="onUpdateFileAlt(instance.uid)"
            *ngIf="fileMetaOperations[instance.uid].editingAlt"
            [(ngModel)]="updateFileAlt"
            class="form-control"
            style="max-width: 250px"
            type="text"
          />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center" style="place-items: center">
          <app-file-preview [file]="instance" [size]="32" style="display: block" />
        </td>
        <td *ngIf="isGeneratedFileMeta(instance)" class="align-content-center">
          <button
            (click)="onOpenNoteModal(noteModal, instance)"
            *ngIf="instance.note"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="{{ instance.note }}"
            triggers="hover"
          >
            <i class="bi bi-journal action-button"></i>
          </button>
          <button
            (click)="onOpenNoteModal(noteModal, instance)"
            *ngIf="!instance.note"
            class="btn btn-no-space btn-outline-dark align-right"
            ngbPopover="Add note"
            triggers="hover"
          >
            <i class="bi bi-plus-circle action-button"></i>
          </button>
        </td>

        <!-- columns for variable and dataModel list -->
        <td
          (click)="onClickOnInstance(instance)"
          *ngIf="isVariable(instance) || isDataModelListInstance(instance)"
          class="cursor-pointer align-content-center"
        >
          {{ instance.name }}
        </td>
        <td
          (click)="onClickOnInstance(instance)"
          *ngIf="isVariable(instance) || isDataModelListInstance(instance)"
          class="cursor-pointer align-content-center"
        >
          {{ instance.description }}
        </td>

        <!-- column for variable-list -->
        <td (click)="onClickOnInstance(instance)" *ngIf="isVariable(instance)" class="cursor-pointer align-content-center">
          {{ instance.isPlayThroughStatistic ? 'Yes' : 'No' }}
        </td>

        <!-- columns for resource-list -->
        <td (click)="onClickOnInstance(instance)" *ngFor="let field of fieldsToDisplay" class="cursor-pointer align-content-center">
          {{ getFieldDisplayValue(instance, field) }}
        </td>
        <td
          (click)="onClickOnInstance(instance)"
          *ngIf="isDataInstance(instance) && fieldsToDisplay.length === 0"
          class="cursor-pointer align-content-center"
        >
          {{ instance.getName() }}
        </td>
        <td
          (click)="onClickOnInstance(instance)"
          *ngIf="isDataInstance(instance) && fieldsToDisplay.length === 0"
          class="cursor-pointer align-content-center"
        >
          {{ instance.structType.typeId }}
        </td>

        <!-- column for non dataModel and pipelineOverview lists -->
        <td
          (click)="onClickOnInstance(instance)"
          *ngIf="!isSchemaListInstance(instance) && !isPipelineOverviewInstance(instance)"
          class="align-content-center"
        >
          {{ instance.modified | date: 'medium' }}
        </td>

        <!-- columns for all the lists except pipelineOverview-->
        <td *ngIf="!isPipelineOverviewInstance(instance)" class="align-content-center">
          <app-tags
            (tagSelected)="onTagSelected($event, instance)"
            [allTags]="allTags"
            [tags]="tagsPerInstance[getUidOfListInstance(instance)]"
          />
        </td>
        <td *ngIf="!isPipelineOverviewInstance(instance)" class="align-content-center" style="width: 240px">
          <div class="d-flex gap-1">
            <!-- buttons for the media-list -->
            <button
              (click)="onOpenReplaceModal(replaceModal, instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="Replace or rename the file content. This will update it everywhere it is used."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-recycle action-button"></span>
            </button>
            <button
              (click)="onClickOnInstance(instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="View the usages of this media."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-search action-button"></span>
            </button>
            <button
              (click)="onDownloadMedia(instance)"
              *ngIf="isGeneratedFileMeta(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              ngbPopover="Download media"
              triggers="hover"
            >
              <i class="bi bi-download action-button"></i>
            </button>

            <!-- button for both resource- and variable-list -->
            <button
              (click)="onClickOnInstance(instance)"
              *ngIf="isDataInstance(instance) || isVariable(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              id="open-instance"
              ngbPopover="Edit {{ getNameOfListInstance(instance) }}"
              triggers="hover"
            >
              <span class="bi bi-pencil-square action-button"></span>
            </button>

            <!-- button for the dataInstance and dataModel-list -->
            <button
              (click)="onDuplicateInstance(instance)"
              *ngIf="isDataInstance(instance) || isDataModelListInstance(instance)"
              class="btn btn-no-space btn-outline-dark"
              id="duplicate-instance"
              ngbPopover="{{ isDataModelListInstance(instance) ? 'Copy' : 'Duplicate' }} the instance."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-copy action-button"></span>
            </button>

            <!-- button for the resource-list -->
            <button
              (click)="onDownloadInstance(instance)"
              *ngIf="isDataInstance(instance)"
              class="btn btn-no-space btn-outline-dark align-right"
              ngbPopover="Download JSON"
              triggers="hover"
            >
              <i class="bi bi-download action-button"></i>
            </button>

            <!-- buttons for all the lists -->
            <button
              (click)="onCopyIdToClipboard(instance)"
              class="btn btn-no-space btn-outline-dark"
              ngbPopover="Copy the {{ isDataModelListInstance(instance) ? 'Type ID' : 'Uid' }} of the instance to the clipboard."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-code action-button"></span>
            </button>
            <button
              (click)="onDeleteInstance(instance)"
              class="btn btn-no-space btn-outline-dark"
              id="delete-instance"
              ngbPopover="Permanently delete the instance."
              triggers="hover"
              type="button"
            >
              <span class="bi bi-trash action-button"></span>
            </button>
          </div>
        </td>
        <td *ngIf="isPipelineOverviewInstance(instance)" [style]="'max-width: 400px'" class="align-content-center">
          <span (click)="onSelectInstance(instance)" class="text-center mb-1" role="none">
            <i
              *ngIf="instance.outdated"
              [ngbPopover]="'This result is outdated because a source value changed!'"
              class="bi bi-exclamation-triangle text-danger"
              triggers="hover"
            ></i>
            <i
              *ngIf="!instance.pickedResult"
              [ngbPopover]="'There is no result picked for this instance!'"
              class="bi bi-question-circle text-warning"
              triggers="hover"
            ></i>
            {{ instance.dataInstance.getName() }} <br
          /></span>
          <button (click)="onRunPipeline(instance)" [disabled]="instance.loading" class="btn btn-primary btn-sm">
            <i class="bi bi-lightning"></i>Run
          </button>
          <button (click)="onClickOnInstance(instance)" class="btn btn-primary btn-sm ms-1"><i class="bi bi-search"></i>View</button>
        </td>
        <td *ngIf="isPipelineOverviewInstance(instance)" class="align-content-center">
          <span *ngIf="!instance.pickedResult" class="align-content-center">There is no result picked for this instance.</span>
          <div
            *ngIf="instance.pickedResult"
            [ngStyle]="{ 'border-color': 'var(--bs-success) !important' }"
            class="d-block border text-center d-flex flex-column h-fit"
          >
            <span *ngIf="instance.pickedResult.type === 'Text'" [style]="'max-height:200px'" class="overflow-auto p-1">
              {{ instance.pickedResult.result.value }}
            </span>
            <span *ngIf="instance.pickedResult.type === 'Audio' || instance.pickedResult.type === 'Image'" class="p-1"
              ><app-file-detailed-preview [file]="instance.pickedResult.media"
            /></span>
            <div [ngStyle]="{ 'background-color': 'var(--bs-success) !important' }" class="d-flex gap-2 justify-content-center py-1">
              <button [disabled]="true" class="btn btn-success btn-sm">
                <i class="bi bi-check"></i>
              </button>
              <button
                (click)="onUpdateStatusOfResult(instance.pickedResult, instance, GeneratedAIPipelineStepResult.StatusEnum.Undecided)"
                class="btn btn-warning btn-sm"
              >
                <i class="bi bi-question"></i>
              </button>
              <button
                (click)="onUpdateStatusOfResult(instance.pickedResult, instance, GeneratedAIPipelineStepResult.StatusEnum.Rejected)"
                class="btn btn-danger btn-sm"
              >
                <i class="bi bi-x"></i>
              </button>
            </div>
          </div>
        </td>
        <td *ngIf="isPipelineOverviewInstance(instance)" class="align-content-center">
          <div *ngIf="instance.loading">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div *ngIf="!instance.loading" class="d-flex gap-2 flex-wrap">
            <span *ngIf="instance.results.length === 0" class="align-content-center">There are no results yet for this instance.</span>
            <div
              *ngFor="let result of instance.results"
              [ngStyle]="{
                'border-color':
                  (result.result.status === GeneratedAIPipelineStepResult.StatusEnum.Picked
                    ? 'var(--bs-success)'
                    : result.result.status === GeneratedAIPipelineStepResult.StatusEnum.Rejected
                      ? 'var(--bs-danger)'
                      : 'var(--bs-warning)') + '!important',
              }"
              class="d-block border text-center d-flex flex-column"
            >
              <span *ngIf="result.type === 'Text'" [style]="'max-height:200px'" class="overflow-auto p-1">{{ result.result.value }}</span>
              <span *ngIf="result.type === 'Audio' || result.type === 'Image'" class="p-1"
                ><app-file-detailed-preview [file]="result.media"
              /></span>
              <div
                [ngStyle]="{
                  'background-color':
                    (result.result.status === GeneratedAIPipelineStepResult.StatusEnum.Picked
                      ? 'var(--bs-success)'
                      : result.result.status === GeneratedAIPipelineStepResult.StatusEnum.Rejected
                        ? 'var(--bs-danger)'
                        : 'var(--bs-warning)') + '!important',
                }"
                class="d-flex gap-2 justify-content-center py-1"
              >
                <button
                  (click)="onUpdateStatusOfResult(result, instance, GeneratedAIPipelineStepResult.StatusEnum.Picked)"
                  [disabled]="result.result.status === GeneratedAIPipelineStepResult.StatusEnum.Picked"
                  class="btn btn-success btn-sm"
                >
                  <i class="bi bi-check"></i>
                </button>
                <button
                  (click)="onUpdateStatusOfResult(result, instance, GeneratedAIPipelineStepResult.StatusEnum.Undecided)"
                  [disabled]="result.result.status === GeneratedAIPipelineStepResult.StatusEnum.Undecided"
                  class="btn btn-warning btn-sm"
                >
                  <i class="bi bi-question"></i>
                </button>
                <button
                  (click)="onUpdateStatusOfResult(result, instance, GeneratedAIPipelineStepResult.StatusEnum.Rejected)"
                  [disabled]="result.result.status === GeneratedAIPipelineStepResult.StatusEnum.Rejected"
                  class="btn btn-danger btn-sm"
                >
                  <i class="bi bi-x"></i>
                </button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="d-flex pb-2 float-end">
    <button
      (click)="onPageChange(currentPage - 1)"
      [disabled]="currentPage === 1"
      class="btn btn-secondary ms-1"
      ngbPopover="Previous page"
      triggers="hover"
    >
      <span class="bi bi-arrow-left"></span>
    </button>
    <button
      (click)="onPageChange(currentPage + 1)"
      [disabled]="currentPage === maxPages"
      class="btn btn-secondary ms-1"
      ngbPopover="Next page"
      triggers="hover"
    >
      <span class="bi bi-arrow-right"></span>
    </button>
    <select
      (change)="onChangePageSize()"
      [(ngModel)]="pageSize"
      class="form-select ms-1"
      id="paginated-size-select2"
      style="max-width: 100px"
    >
      <option *ngFor="let size of ['25', '50', '75', '100', 'all']" [selected]="size === pageSize" [value]="size">{{ size }}</option>
    </select>
  </div>
</div>

<ng-template #replaceModal let-modal>
  <div class="modal-header" id="replace-file-modal-header">
    <h5 class="modal-title">Replace media</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="replace-file-modal-close-button"
      type="button"
    ></button>
  </div>
  <div class="modal-body">
    <form (submit)="onSubmitFileReplace(modal)">
      <div class="form-group">
        <input #fileUpload (change)="onFileUploadSelected($event)" class="hidden" id="fileUpload" type="file" />

        <div class="file-upload">
          {{ replaceFileName || 'No file uploaded yet.' }}

          <button
            (click)="fileUpload.click()"
            class="btn btn-primary btn-circle btn-xl"
            id="upload-file-from-local-storage-button"
            type="button"
          >
            <span class="bi bi-paperclip"></span>
          </button>
        </div>
      </div>
      <div *ngIf="replaceFileName" class="form-group">
        <label for="name">Name: </label>
        <input [(ngModel)]="replaceFileName" class="form-control" id="name" name="name" placeholder="Type name..." type="text" />
      </div>
      <div *ngIf="replaceFileName" class="form-group">
        <label for="alt">Description: </label>
        <input [(ngModel)]="replaceFileAlt" class="form-control" id="alt" name="alt" placeholder="Type description..." type="text" />
      </div>
      <button [disabled]="!replaceFileUid" class="btn btn-primary btn-space" type="submit">Submit</button>
    </form>
  </div>
</ng-template>

<ng-template #noteModal let-modal>
  <div class="modal-header" id="note-modal-header">
    <h5 class="modal-title">Note</h5>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" id="note-modal-close-button" type="button"></button>
  </div>
  <div class="modal-body">
    <form (submit)="onSaveNote(modal)">
      <div class="form-group">
        <textarea
          [(ngModel)]="currentNote"
          [ngModelOptions]="{ standalone: true }"
          class="form-control"
          id="note"
          placeholder="Leave a note"
          type="text"
        >
        </textarea>
      </div>

      <button [disabled]="!replaceFileUid" class="btn btn-primary btn-space" type="submit">Save</button>
    </form>
  </div>
</ng-template>
