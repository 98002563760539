import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SearchService } from '@services/UI-elements/search.service';
import { MiscEndpoints } from '@services/api';
import { ResourceService } from '@services/resource.service';
import { ThemeService } from '@services/theme.service';
import { EnumTypeRepository } from '@services/repositories';

interface NavItem {
  name: string;
  route: string;
}

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss'],
})
export class HomeScreenComponent {
  /*
   * The home screen component is the first component that is loaded when the app is opened (unless you are not logged in).
   * It only holds the navigation bar where you can access the overview list of all resources.
   * Via routing the correct list and editor components are loaded.
   */

  protected resourceStructsPromise: Promise<NavItem[]>;
  protected readonly versions = [
    `Frontend : Build#${environment.version_latestBuildNumber || '0'} [${(environment.version_latestCommitHash || 'abcdefg').slice(0, 7)}] - ${environment.version_latestDate || 'Unknown'}`,
  ];

  constructor(
    protected searchService: SearchService,
    private resourceService: ResourceService,
    protected themeService: ThemeService,
    private enumTypeRepository: EnumTypeRepository,
    miscEndpoints: MiscEndpoints,
  ) {
    resourceService.onUpdated$.subscribe((structs) => (this.resourceStructsPromise = this.loadResourceNavItems(structs)));
    this.resourceStructsPromise = this.loadResourceNavItems();

    miscEndpoints.getVersion().subscribe((data) => {
      this.versions.push(
        `Backend  : ${data.version.tag.replace('-SNAPSHOT', '')}#${data.version.buildNumber} [${data.version.commitHash.slice(0, 7)}] - ${data.version.timestamp}`,
      );
    });
  }

  showSearch() {
    this.searchService.show();
  }

  toggleDarkMode() {
    const newTheme = this.themeService.currentTheme === 'dark' ? 'light' : 'dark';
    this.themeService.setTheme(newTheme);
  }

  private async loadResourceNavItems(structs?: typeof this.resourceService.resourceStructs): Promise<NavItem[]> {
    if (!structs) await this.resourceService.waitForInit();

    return [
      ...Object.values(structs ?? this.resourceService.resourceStructs).map((struct) => ({
        name: struct.name,
        route: struct.typeId,
      })),
      {
        name: 'Variables',
        route: 'Variable',
      },
      {
        name: 'Media',
        route: 'Media',
      },
      {
        name: 'AI Pipelines',
        route: 'Pipelines',
      },
      ...(await this.enumTypeRepository.getAll())
        .filter((enumType) => enumType.isResource)
        .map((enumType) => ({
          name: enumType.name,
          route: `EnumType/${enumType.typeId}`,
        })),
    ].sort((a, b) => a.name.localeCompare(b.name));
  }
}
