import { GeneratedAIPipelineStepResultSourceValue } from '@services/types/generated';

export interface NewAIPipelineStepResultSourceValue {
  uid: string;
  pipelineStepField: string;
  wildcard: string;
  value: string;
}

export class AIPipelineStepResultSourceValue {
  public readonly uid: string;
  public pipelineStepField: string;
  public wildcard: string;
  public value: string;

  constructor({ uid, pipelineStepField, wildcard, value }: NewAIPipelineStepResultSourceValue) {
    this.uid = uid;
    this.pipelineStepField = pipelineStepField;
    this.wildcard = wildcard;
    this.value = value;
  }

  public static async deserialize(data: GeneratedAIPipelineStepResultSourceValue): Promise<AIPipelineStepResultSourceValue> {
    return new AIPipelineStepResultSourceValue({
      uid: data.uid,
      pipelineStepField: data.pipelineStepField,
      wildcard: data.wildcard,
      value: data.value,
    });
  }

  public async serialize(): Promise<Readonly<GeneratedAIPipelineStepResultSourceValue>> {
    return Object.freeze({
      uid: this.uid,
      pipelineStepField: this.pipelineStepField,
      wildcard: this.wildcard,
      value: this.value,
    });
  }
}
