import { Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AIPipeline } from '@services/entities/AIPipeline';
import { AIPipelineRepository } from '@services/repositories/AIPipelineRepository';
import { GeneratedAIPipelineStep } from '@services/types/generated';
import { cloneDeep } from 'lodash';
import { AIPipelineStep } from '@services/entities/helpers/AIPipelineStep';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { AlertService } from '@services/UI-elements/alert-service';
import { HttpErrorResponse } from '@angular/common/http';

type EditablePipeline = {
  name: string;
  description: string;
  modified: string;
  structTypeId: string;
  steps: GeneratedAIPipelineStep[];
};

@Component({
  selector: 'app-pipeline-editor',
  templateUrl: './pipeline-editor.component.html',
  styleUrls: ['./pipeline-editor.component.scss'],
})
export class PipelineEditorComponent implements OnInit, OnDestroy {
  @ViewChildren('stepNameInput') stepNameInputs!: QueryList<ElementRef>;
  pipelineId?: string;
  pipeline?: AIPipeline;
  editablePipeline: EditablePipeline = { name: '', description: '', modified: '', structTypeId: '', steps: [] };
  aiModelOptions = ['Gpt-4o', 'Midjourney', 'ElevenLabs'];
  editingStepName: Record<string, boolean> = {};
  editingPipelineName = false;
  private routeSub?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private pipelineRepository: AIPipelineRepository,
    private loadingScreenService: LoadingScreenService,
    private router: Router,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(async (params) => {
      this.pipelineId = params['pipelineUid'];
      await this.loadPipeline();
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      this.savePipeline().then();
    }
  }

  async createPipelineStep(previousStepUid?: string, isLast = false) {
    if (!this.pipeline) return;
    const newPipelineStep: GeneratedAIPipelineStep = {
      uid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
      name: '',
      aiModel: '',
      prompt: '',
      results: [],
      previousStepUid: previousStepUid,
      isDraft: true,
      isLast: isLast || this.editablePipeline.steps.length === 0,
    };
    const nextPipelineStepIndex = previousStepUid
      ? this.editablePipeline.steps.findIndex((step) => step.previousStepUid === previousStepUid)
      : this.editablePipeline.steps.findIndex((step) => step.previousStepUid === null);
    if (nextPipelineStepIndex !== -1) {
      this.editablePipeline.steps[nextPipelineStepIndex].previousStepUid = newPipelineStep.uid;
      const firstPart = this.editablePipeline.steps.slice(0, nextPipelineStepIndex);
      const secondPart = this.editablePipeline.steps.slice(nextPipelineStepIndex);
      this.editablePipeline.steps = [...firstPart, newPipelineStep, ...secondPart];
    } else {
      this.editablePipeline.steps.push(newPipelineStep);
    }

    if (isLast) {
      this.editablePipeline.steps.find((step) => step.uid === previousStepUid)!.isLast = false;
    }
    this.editPipelineStepName(newPipelineStep.uid);
  }

  async savePipeline() {
    if (!this.pipeline) return;
    try {
      await this.loadingScreenService.show(async () => {
        this.pipeline!.name = this.editablePipeline.name;
        this.pipeline!.description = this.editablePipeline.description;
        this.pipeline!.structTypeId = this.editablePipeline.structTypeId;
        this.pipeline!.steps = await Promise.all(this.editablePipeline.steps.map(async (step) => AIPipelineStep.deserialize(step)));
        await this.pipeline!.save();
        await this.loadPipeline();
      });
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        return this.alertService.error('Failed to save pipeline: ' + e.error.message);
      }
      console.error(e);
    }
  }

  deletePipelineStep(step: GeneratedAIPipelineStep) {
    this.editablePipeline.steps.forEach((s) => {
      if (s.previousStepUid === step.uid) {
        s.previousStepUid = step.previousStepUid;
      }
    });
    if (step.isLast) {
      this.editablePipeline.steps.find((s) => s.uid === step.previousStepUid)!.isLast = true;
    }
    this.editablePipeline.steps = this.editablePipeline.steps.filter((s) => s.uid !== step.uid);
  }

  editPipelineName() {
    this.editingPipelineName = true;
    setTimeout(() => {
      const input = document.getElementById('pipeline-name');
      if (input) {
        input.focus();
      }
    }, 0);
  }

  editPipelineStepName(stepUid: string) {
    this.editingStepName[stepUid] = true;
    setTimeout(() => {
      const input = this.stepNameInputs.find((inputRef) => inputRef.nativeElement.id === `step-name${stepUid}`);
      if (input) {
        input.nativeElement.focus();
      }
    }, 0);
  }

  toOverview() {
    this.router.navigate(['../'], { relativeTo: this.route }).then();
  }

  private async loadPipeline() {
    if (!this.pipelineId) {
      console.error('No pipeline ID provided');
      return;
    }

    this.pipeline = await this.pipelineRepository.get(this.pipelineId);
    const steps = [];
    let nextStep = this.pipeline.steps.find((step) => !step.previousStepUid);
    while (nextStep) {
      steps.push(cloneDeep(await nextStep.serialize()));
      this.editingStepName[nextStep.uid] = nextStep.name === '';
      nextStep = this.pipeline.steps.find((step) => step.previousStepUid === nextStep!.uid);
    }
    this.editablePipeline = {
      name: this.pipeline.name,
      description: this.pipeline.description ?? '',
      modified: this.pipeline.modified,
      structTypeId: this.pipeline.structTypeId,
      steps: steps,
    };
  }
}
