import { AreaPlugin } from 'rete-area-plugin';
import { BaseSchemes } from 'rete';
import { Preset } from 'rete-dock-plugin/_types/presets/types';

function getContainer() {
  const element = document.createElement('div');
  element.className = 'dock-container';
  const { style } = element;

  style.position = 'absolute';
  style.overflowY = 'auto';
  style.boxSizing = 'border-box';
  style.left = '0';
  style.top = '47px';
  style.paddingTop = '10px';
  style.height = '-webkit-fill-available';
  style.display = 'flex';
  style.flexDirection = 'column';
  style.background = 'var(--grid-background-color)';
  style.zIndex = '100';

  element.addEventListener('pointerdown', (e) => {
    e.stopPropagation();
  });
  element.addEventListener('contextmenu', (e) => {
    e.stopPropagation();
  });
  element.addEventListener('wheel', (e) => {
    e.stopPropagation();
  });

  return element;
}

function getNodeContainer() {
  const element = document.createElement('div');
  const { style } = element;

  style.transform = `scale(0.5)`;
  style.verticalAlign = 'top';
  style.marginInline = `-50px`;
  style.marginBlock = `-15px`;
  return element;
}

export function setup<T>(area: AreaPlugin<BaseSchemes, T>): Preset {
  const container = getContainer();

  area.container.appendChild(container);

  return {
    createItem(index) {
      const element = getNodeContainer();
      const beforeChild = typeof index !== 'undefined' ? container.children[index] : null;

      container.insertBefore(element, beforeChild);

      return element;
    },
    removeItem(element) {
      container.removeChild(element);
    },
  };
}
