<div *ngIf="structType && pipelineUid && pipelineInDraft" class="container">
  <div class="d-flex justify-content-between gap-1 align-items-center">
    <div>
      <h1 class="text-center text-white pb-2 drop-shadow">
        Pipeline '{{ pipelineInDraft.name }}' for StructType '{{ structType.name | prettifyName }}'
      </h1>
      <p>{{ pipelineInDraft.description }}</p>
    </div>

    <div class="d-flex gap-2">
      <button (click)="openElevenlabsDictionary()" class="btn btn-primary h-fit"><i class="bi bi-file"></i> Dictionary</button>
      <button (click)="runPipelineForUndecidedInstances()" [disabled]="allInstancesPicked" class="btn btn-primary h-fit">
        <i class="bi bi-lightning"></i> Run all without picked result
      </button>
      <button (click)="editPipeline()" class="btn btn-primary h-fit"><i class="bi bi-pencil"></i> Edit</button>
    </div>
  </div>

  <app-instances-list
    (downloadPickedMediaEmitter)="onDownloadPickedMedia($event)"
    (runPipelineEmitter)="runPipelineFromList($event)"
    (selectInstanceEmitter)="openInstance($event)"
    (updateResultStatusEmitter)="updateResultStatus($event)"
    *ngIf="pipelineOverviewInstances.length > 0"
    [allInstances]="pipelineOverviewInstances"
    [listType]="'pipelineOverview'"
  ></app-instances-list>
</div>
