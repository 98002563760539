import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AIPipeline } from '@services/entities';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'app-pipeline-select-modal',
  templateUrl: './pipeline-select-modal.component.html',
  standalone: true,
  imports: [NgForOf, NgIf],
})
export class PipelineSelectModalComponent {
  @Input({ required: true }) pipelines!: AIPipeline[];

  @Output() selectedPipeline = new EventEmitter<AIPipeline>();
  @Output() createdPipeline = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<void>();

  selectPipeline(pipeline: AIPipeline) {
    this.selectedPipeline.emit(pipeline);
    this.close();
  }

  createPipeline() {
    this.createdPipeline.emit();
    this.close();
  }

  close() {
    this.closeModal.emit();
  }
}
