<div class="resource-title">
  <h1 [ngbPopover]="structTypeDescription" class="text-center text-white pb-2 drop-shadow" id="{{ structTypeId }}-header" triggers="hover">
    {{ structType?.name | prettifyName }}
  </h1>
  <button (click)="createResource()" class="btn btn-primary" id="create-{{ structTypeId }}-button">
    <i class="bi bi-plus-circle"></i>
  </button>
  <button (click)="openModal(importDataModal)" class="btn btn-primary" title="Upload CSV">
    <div><i class="bi bi-upload"></i></div>
  </button>
  <button (click)="openModal(pipelineSelectModal)" class="btn btn-secondary" title="AI pipelines">
    <i class="bi bi-robot"></i>
  </button>
</div>
<app-instances-list
  (bulkTagSelectedEmitter)="onBulkTagSelected($event)"
  (deleteInstanceEmitter)="deleteResource($event)"
  (downloadInstanceEmitter)="downloadResource($event)"
  (duplicateInstanceEmitter)="duplicateResource($event)"
  (selectInstanceEmitter)="openResource($event)"
  (tagSelectedEmitter)="onTagSelected($event)"
  *ngIf="!loading && resources.length > 0"
  [allInstances]="resources"
  [allTags]="allTags"
  [fieldsToDisplay]="fieldsToDisplay"
  [listType]="structTypeId"
  [tagsPerInstance]="tagsPerResource"
/>

<ng-template #duplicationCompleteModal let-modal>
  <app-duplication-complete-modal
    (closeModal)="modalService.dismissAll()"
    (openInstance)="openResource(newResource!)"
    [info]="{ name: resourceName(newResource!) }"
  />
</ng-template>

<ng-template #pipelineSelectModal let-modal>
  <app-pipeline-select-modal
    (closeModal)="modal.dismiss('Cross click')"
    (createdPipeline)="createPipeline()"
    (selectedPipeline)="selectPipeline($event)"
    [pipelines]="pipelines"
  />
</ng-template>

<ng-template #importDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Import data</h5>
    <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="btn-close" type="button"></button>
  </div>

  <div class="modal-body">
    <div class="form-group mb-2">
      <label for="csv-file">Select CSV file</label>
      <input (change)="onFileChange($event)" accept="text/csv" class="form-control" id="csv-file" type="file" />
    </div>
    <i *ngIf="importDataHeaders.length === 0">Note: the headers of the csv columns must match exactly with the fieldId's of this struct</i>

    <div *ngIf="importDataHeaders.length > 0" class="mb-2">
      <div>
        <input [(ngModel)]="overwriteExisting" class="form-check-input me-2" type="checkbox" />
        <span>Overwrite existing instances</span>
      </div>
      <div>
        <input [(ngModel)]="deleteMissing" class="form-check-input me-2" type="checkbox" />
        <span>Delete missing instances</span>
      </div>
    </div>

    <table *ngIf="importDataHeaders.length > 0" class="table table-striped">
      <thead>
        <th>Column</th>
        <th>
          Values to skip
          <i class="bi bi-info-circle" title="Comma seperated list of values which skip the row if present in this column"></i>
        </th>
        <th>Is Key <i class="bi bi-info-circle" title="Select if this column is the unique identifier of each row"></i></th>
      </thead>
      <tbody>
        <tr *ngFor="let header of importDataHeaders">
          <td class="align-content-center">
            <input [(ngModel)]="header.keep" [disabled]="!header.fieldId" class="form-check-input me-2" type="checkbox" />
            <span [ngClass]="!header.fieldId ? 'text-decoration-line-through' : ''">{{ header.header }}</span>
          </td>
          <td class="align-content-center">
            <input [(ngModel)]="header.valuesToSkip" [disabled]="!header.fieldId" class="form-control" type="text" />
          </td>
          <td class="align-content-center">
            <input [(ngModel)]="header.isKey" [disabled]="!header.fieldId" class="form-check-input" type="checkbox" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="modal-footer">
    <button (click)="importCSVData()" class="btn btn-primary">Import</button>
  </div>
</ng-template>
