export const environment = {
  production: false,
  authServerHost: 'https://api.leerplus.dev.gametailors.com/auth/',
  casHost: 'https://api.leerplus.dev.gametailors.com/cas',
  dataPackage: 'leerplus-dev',
  defaultGame: 'leerplus-dev',
  aspectRatio: { width: 1920, height: 1080 },
  playGameUrl: 'https://play.leerplus.dev.gametailors.com/',

  version_latestBuildNumber: '1404',
  version_latestCommitHash: '5e4d80a6f59c596b413f7d425380671e55a2a155',
  version_latestDate: '02/04/2025 17:52',
};
