import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './_components/home-screen/login/login.component';
import { HomeScreenComponent } from './_components/home-screen/home-screen.component';
import { authGuard } from '@services/authorization/auth.guard';
import { ResourceListComponent } from './_components/lists/resource-list/resource-list.component';
import { StructInstanceEditorComponent } from './_components/editors/struct-instance-editor/struct-instance-editor.component';
import { MapEditorComponent } from './_components/editors/map/map-editor/map-editor.component';
import { ModuleEditorComponent } from './_components/editors/module/module-editor/module-editor.component';
import { SchemaListComponent } from './_components/schema-editor/schema-list/schema-list.component';
import { SchemaTableComponent } from './_components/schema-editor/schema-table/schema-table.component';
import { environment } from '../environments/environment';
import { VariableListComponent } from './_components/lists/variable-list/variable-list.component';
import { VariableEditorComponent } from './_components/editors/variable/variable-editor/variable-editor.component';
import { MaintenanceScreenComponent } from './_components/maintenance-screen/maintenance-screen.component';
import { SavedChangesCheckerGuard } from '@guards/saved-changes-checker.guard';
import { MediaListComponent } from './_components/lists/media-list/media-list.component';
import { MissionEditorComponent } from './_components/editors/mission/mission-editor/mission-editor.component';
import { GameRequestTestScreenComponent } from './_components/game-request-test-screen/game-request-test-screen.component';
import { EnumListComponent } from './_components/lists/enum-list/enum-list.component';
import { PipelineEditorComponent } from './_components/AI-pipelines/pipeline-editor/pipeline-editor.component';
import { PipelineOverviewComponent } from './_components/AI-pipelines/pipeline-overview/pipeline-overview.component';
import { PipelineListComponent } from './_components/AI-pipelines/pipeline-list/pipeline-list.component';
import { ElevenlabsDictionaryComponent } from './_components/AI-pipelines/elevenlabs-dictionary/elevenlabs-dictionary.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    title: `${environment.defaultGame.charAt(0).toUpperCase() + environment.defaultGame.slice(1)} - CAS`,
    component: HomeScreenComponent,
    canActivate: [authGuard],
    children: [
      { path: 'MissionInfo/:missionInfoUid', component: MissionEditorComponent },
      // This is a temporary redirect to the MissionInfo component because the routing has recently been changed
      { path: 'mission/:missionUid', redirectTo: 'MissionInfo/:missionUid' },
      { path: 'Module/:moduleUid', component: ModuleEditorComponent },
      { path: 'Map/:mapUid', component: MapEditorComponent },
      {
        path: 'Media',
        component: MediaListComponent,
      },
      {
        path: 'data-model',
        component: SchemaListComponent,
      },
      {
        path: 'maintenance',
        component: MaintenanceScreenComponent,
      },
      {
        path: 'data-model/:type/:typeId',
        component: SchemaTableComponent,
        canDeactivate: [SavedChangesCheckerGuard],
      },
      {
        path: 'Pipelines',
        component: PipelineListComponent,
      },
      {
        path: ':structTypeId/pipelines/:pipelineUid',
        component: PipelineOverviewComponent,
      },
      {
        path: ':structTypeId/pipelines/:pipelineUid/edit',
        component: PipelineEditorComponent,
      },
      {
        path: 'ElevenlabsDictionary',
        component: ElevenlabsDictionaryComponent,
      },
      ...(environment.production
        ? []
        : [
            {
              path: 'game-request-test',
              component: GameRequestTestScreenComponent,
            },
          ]),
      { path: 'Variable', component: VariableListComponent },
      { path: 'Variable/:variableRef', component: VariableEditorComponent, canDeactivate: [SavedChangesCheckerGuard] },
      // This is a temporary redirect to the MissionInfo component because the routing has recently been changed
      { path: 'variable/:variableRef', redirectTo: 'Variable/:variableRef' },
      { path: 'EnumType/:enumType', component: EnumListComponent },
      { path: ':resource', component: ResourceListComponent },
      { path: ':resource/:resourceUid', component: StructInstanceEditorComponent },
    ],
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { canceledNavigationResolution: 'computed' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
