<div class="position-relative">
  <div #rete class="rete" id="#rete"></div>

  <div class="position-absolute bottom-0">
    <div *ngIf="flowchartType === FlowchartType.MODULE">
      <button class="btn btn-primary btn-sm" data-bs-target="#newKennisNodeModal" data-bs-toggle="modal">
        <i class="bi-plus-circle"></i> Add node
      </button>
    </div>
  </div>

  <div class="position-absolute bottom-0 end-0 p-1 d-flex flex-column gap-1">
    <div class="d-flex justify-content-end">
      <button
        (click)="arrangeNodes()"
        class="btn btn-primary btn-sm ms-1"
        ngbPopover="Arrange nodes"
        style="font-size: medium"
        triggers="hover"
      >
        <i class="bi-shuffle"></i>
      </button>

      <button
        (click)="zoomAtAllNodes()"
        class="btn btn-primary btn-sm ms-1"
        ngbPopover="Fit to screen"
        style="font-size: medium"
        triggers="hover"
      >
        <i class="bi-fullscreen"></i>
      </button>

      <button
        (click)="showPasteModal()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        class="btn btn-primary btn-sm ms-1"
        ngbPopover="Paste activities"
        style="font-size: medium"
        triggers="hover"
      >
        <i class="bi-clipboard-plus-fill"></i>
      </button>
    </div>

    <div class="d-flex">
      <button
        (click)="copySelectedNodes()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        [disabled]="!selector || selector.entities.size === 0"
        class="btn btn-primary btn-sm ms-1"
      >
        Copy selected
      </button>
      <button
        (click)="duplicateSelectedNodes()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        [disabled]="!selector || selector.entities.size === 0"
        class="btn btn-primary btn-sm ms-1"
      >
        Duplicate selected
      </button>
      <button
        (click)="deleteSelectedNodes()"
        *ngIf="flowchartType === FlowchartType.MISSION"
        [disabled]="!selector || selector.entities.size === 0"
        class="btn btn-danger btn-sm ms-1"
      >
        Delete selected
      </button>
    </div>
  </div>
</div>

<ng-template #pasteModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">Paste Activities</h4>
    <button (click)="modal.dismiss('cross click')" aria-describedby="modal-title" class="btn-close" type="button">&nbsp;</button>
  </div>

  <div class="modal-body">
    <p>Enter the UIDs of the activities to be pasted. Separate multiple UIDs with a comma.</p>

    <textarea
      (paste)="formatPastedActivities()"
      [(ngModel)]="pasteActivityUids"
      class="form-control"
      rows="5"
      style="max-height: 50vh"
      type="text"
    ></textarea>
  </div>

  <div class="modal-footer">
    <button (click)="modal.dismiss('cancel click')" class="btn btn-outline-secondary" type="button">Cancel</button>

    <button (click)="modal.close('confirm click')" [disabled]="!pasteActivityUids.length" class="btn btn-primary" type="button">
      Paste
    </button>
  </div>
</ng-template>

<div
  #newKennisNodeModal
  aria-hidden="true"
  aria-labelledby="newKennisNodeLabel"
  class="modal fade"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  id="newKennisNodeModal"
  tabindex="-1"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newKennisNodeModalLabel">Add new Kennisnode</h5>
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
      </div>
      <div class="modal-body">
        <input #kennisNodeTitle class="form-control" placeholder="Title of kennisnode" type="text" />
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" data-bs-dismiss="modal" type="button">Close</button>
        <button (click)="createKennisNode(kennisNodeTitle.value)" class="btn btn-primary" data-bs-dismiss="modal" type="button">Add</button>
      </div>
    </div>
  </div>
</div>
