<div class="p-4 container-fluid">
  <div class="card shadow-sm">
    <div class="card-header">
      <h2>Datamodel editor</h2>
    </div>

    <div class="card-body d-flex flex-column gap-3">
      <div class="d-flex justify-content-between">
        <button [routerLink]="'/home/maintenance'" class="btn btn-primary" style="width: fit-content">Go to maintenance page</button>
        <div class="dropdown">
          <button
            aria-expanded="false"
            aria-haspopup="true"
            class="btn btn-primary dropdown-toggle"
            data-bs-toggle="dropdown"
            id="dropdownMenuButton"
            title="New"
            type="button"
          >
            <i class="bi bi-plus-lg"></i>
          </button>

          <div aria-labelledby="dropdownMenuButton" class="dropdown-menu">
            <button (click)="openModal(createModal, [])" class="dropdown-item" id="add-table-button">Table</button>
            <button (click)="openModal(createWithJSONModal, [], { size: 'xl' })" class="dropdown-item">Table(s) from clipboard</button>
          </div>
        </div>
      </div>

      <app-instances-list
        (bulkCopyInstanceEmitter)="openModal(filterTablesToCopy, $event)"
        (deleteInstanceEmitter)="deleteTable($event)"
        (duplicateInstanceEmitter)="openModal(filterTablesToCopy, [$event])"
        (selectInstanceEmitter)="openTable($event)"
        (tagSelectedEmitter)="console.log($event)"
        [allInstances]="schema"
        [allTags]="tags"
        [listType]="'DataModel'"
        [tagsPerInstance]="tagsPerInstance"
      ></app-instances-list>
    </div>
  </div>
</div>

<ng-template #createModal let-modal>
  <div class="modal-header">Create new</div>
  <div class="modal-body d-flex flex-column gap-3">
    <select #typeSelect class="form-select" id="table-type-selector">
      <option selected value="StructType">StructType</option>
      <option value="EnumType">EnumType</option>
      <option value="SelectType">SelectType</option>
    </select>

    <div>
      <div class="pb-1">Name</div>
      <input #nameInput class="form-control" id="name-of-table" type="text" />
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
      <button (click)="createTable(typeSelect.value, nameInput.value)" class="btn btn-primary" id="create-table-button">Create</button>
    </div>
  </div>
</ng-template>

<ng-template #createWithJSONModal let-modal>
  <div class="p-3">
    <div class="modal-header">From clipboard</div>
    <div class="modal-body">
      <textarea
        #jsonArea
        class="form-control"
        placeholder="Paste your table(s)..."
        style="min-height: 50vh; font-family: Monaco, 'Lucida Console', monospace"
        type="text"
      ></textarea>
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
      <button (click)="processClipboardData(jsonArea.value)" class="btn btn-primary">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #filterTablesToCopy let-modal>
  <div class="p-3">
    <div class="modal-header">Filter selected tables</div>
    <div class="modal-body">
      Selecting tags will remove those tables/fields from the copy
      <button (click)="selectAllFilters()" class="badge border-0 bg-primary">Select all</button>
      <button (click)="selectNoFilters()" class="badge border-0 bg-primary">Remove selection</button>
      <div
        (click)="selectFilterForTableCopy(tag)"
        *ngFor="let tag of tags"
        [ngStyle]="{
          'background-color': isTagUsedForFilterTableCopy[tag.uid] ? tag.color : 'var(--bs-gray)',
          border: 'none',
          color: isTagUsedForFilterTableCopy[tag.uid] ? tag.contrastColor : 'white',
        }"
        class="badge align-content-center ms-1 schema-tag cursor-pointer"
        role="none"
      >
        {{ tag.name }}
      </div>
    </div>

    <div class="modal-footer">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-secondary">Cancel</button>
      <button (click)="copyTables(selectedTables)" class="btn btn-primary">Copy</button>
    </div>
  </div>
</ng-template>
