<div class="resource-title">
  <h1 class="text-white drop-shadow">AI Pipelines</h1>
  <button (click)="onOpenNewPipelineModal(newPipelineModal)" class="btn btn-primary" id="create-variable-button">
    <i class="bi bi-plus-circle"></i>
  </button>
  <button (click)="openElevenlabsDictionary()" class="btn btn-primary h-fit"><i class="bi bi-file"></i> Dictionary</button>
</div>
<div class="container pb-2 mt-2">
  <table
    (matSortChange)="sort($event)"
    [matSortActive]="this.sortingPreferences['Pipelines'].active"
    [matSortDirection]="this.sortingPreferences['Pipelines'].direction"
    class="table table-striped table-hover"
    matSort
  >
    <thead>
      <th mat-sort-header="name" scope="col">Name</th>
      <th mat-sort-header="structType" scope="col">StructType</th>
      <th mat-sort-header="description" scope="col">Description</th>
    </thead>
    <tbody>
      <tr (click)="selectPipeline(pipeline)" *ngFor="let pipeline of pipelines" class="cursor-pointer">
        <td class="align-content-center">{{ pipeline.name }}</td>
        <td class="align-content-center">{{ pipeline.structTypeId | prettifyName }}</td>
        <td class="align-content-center">{{ pipeline.description }}</td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #newPipelineModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">New AI Pipeline</h5>
    <button
      (click)="modal.dismiss('Cross click')"
      aria-label="Close"
      class="btn-close"
      id="new-pipeline-modal-close-button"
      type="button"
    ></button>
  </div>
  <div *ngIf="structTypes.length > 0" class="modal-body">
    <div class="form-group">
      <label for="pipeline-struct">StructType</label>
      <select [(ngModel)]="structForPipeline" class="form-control" id="pipeline-struct">
        <option *ngFor="let structType of structTypes" [value]="structType.typeId">
          {{ structType.name }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="pipeline-name">Name</label>
      <input [(ngModel)]="nameNewPipeline" class="form-control" id="pipeline-name" type="text" />
    </div>
    <button (click)="createPipeline()" [disabled]="!structForPipeline || !nameNewPipeline" class="btn btn-primary mt-2">Create</button>
  </div>
</ng-template>
