<div *ngIf="!hideIfEmpty || !currentResourceFieldComponents || currentResourceFieldComponents.length > 0" class="card">
  <button
    *ngIf="showHeader"
    [attr.data-bs-target]="'#content-' + currentResourceUid"
    [ngbPopover]="structTypeDescription"
    class="card-header justify-content-between d-flex position-relative"
    data-bs-toggle="collapse"
    triggers="hover"
  >
    <div *ngIf="data" class="align-content-center"><i *ngIf="icon" [class]="icon"></i> {{ data.structType.name | prettifyName }}</div>
    <div class="d-flex gap-2">
      <button
        (click)="openModal(pipelineSelectModal)"
        *ngIf="pipelines.length > 0"
        class="btn btn-secondary delete-button"
        title="AI pipelines"
      >
        <i class="bi bi-robot"></i>
      </button>
      <button (click)="onDelete()" *ngIf="showDeleteButton" class="btn btn-danger delete-button" id="delete-struct-instance-button">
        <i class="bi bi-trash"></i>
      </button>
    </div>
  </button>

  <div [ngClass]="{ show: !startCollapsed }" class="collapse" id="content-{{ currentResourceUid }}">
    <div *ngFor="let fieldComponent of currentResourceFieldComponents">
      <app-field [data]="fieldComponent.data" [fieldComponent]="fieldComponent.editor" />
    </div>

    <div *ngIf="currentResourceFieldComponents && !currentResourceFieldComponents.length && showHeader">
      <p class="m-2">This struct has no fields.</p>
    </div>
  </div>

  <ng-content select="[slot=custom]"></ng-content>
</div>

<ng-template #pipelineSelectModal let-modal>
  <app-pipeline-select-modal
    (closeModal)="modal.dismiss('Cross click')"
    (createdPipeline)="createPipeline()"
    (selectedPipeline)="selectPipeline($event)"
    [pipelines]="pipelines"
  />
</ng-template>
