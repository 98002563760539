<div class="container">
  <button (click)="toOverview()" class="btn btn-primary my-2"><i class="bi bi-arrow-left"></i> Back to overview</button>
  <div *ngIf="pipeline" class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <h2 *ngIf="!editingPipelineName">{{ editablePipeline.name }}</h2>
        <button (click)="editPipelineName()" *ngIf="!editingPipelineName" class="btn btn-sm ms-1">
          <i class="bi bi-pencil"></i>
        </button>
        <input
          #stepNameInput
          (blur)="editingPipelineName = false"
          *ngIf="editingPipelineName"
          [(ngModel)]="editablePipeline.name"
          class="form-control"
          id="pipeline-name"
          type="text"
        />
      </div>

      <button (click)="savePipeline()" class="btn btn-secondary">Save</button>
    </div>
    <div class="input-group mode-container">
      <div class="d-flex">
        <div class="mode-normal input-group-prepend h-100">
          <span class="input-group-text gap-1 rounded-0 text"> Description </span>
        </div>
      </div>

      <div class="form-control align-content-center p-0 rounded-0">
        <textarea [(ngModel)]="editablePipeline.description" class="form-control" type="text"></textarea>
      </div>
    </div>
    <div class="text-center m-2">
      <button (click)="createPipelineStep()" class="btn btn-secondary"><i class="bi bi-plus-circle"></i> Add step</button>
      <div *ngFor="let pipelineStep of editablePipeline.steps; let i = index">
        <div class="card my-2">
          <h4 class="card-header d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <span class="me-1">{{ i + 1 }}.</span>
              <span *ngIf="!editingStepName[pipelineStep.uid]" class="cursor-pointer" role="none">{{ pipelineStep.name }} </span>
              <button (click)="editPipelineStepName(pipelineStep.uid)" *ngIf="!editingStepName[pipelineStep.uid]" class="btn btn-sm ms-1">
                <i class="bi bi-pencil"></i>
              </button>
              <input
                #stepNameInput
                (blur)="editingStepName[pipelineStep.uid] = false"
                *ngIf="editingStepName[pipelineStep.uid]"
                [(ngModel)]="pipelineStep.name"
                class="form-control"
                id="step-name{{ pipelineStep.uid }}"
                type="text"
              />
            </div>

            <button (click)="deletePipelineStep(pipelineStep)" class="btn btn-danger"><i class="bi bi-trash"></i></button>
          </h4>

          <div class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text"> AI Model </span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <select [(ngModel)]="pipelineStep.aiModel" class="form-select">
                <option *ngFor="let aiModel of aiModelOptions" [value]="aiModel">
                  {{ aiModel }}
                </option>
              </select>
            </div>
          </div>

          <div *ngIf="pipelineStep.aiModel === 'ElevenLabs'" class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text justify-content-between">
                  Voice Id
                  <i
                    [ngbPopover]="
                      'Use the syntax {\{step:1}} to use the result of step 1. Use the syntax {\{field:test}} to use the value of the field with id test of the instance.'
                    "
                    class="bi bi-info-circle"
                    container="body"
                    triggers="hover"
                  ></i>
                </span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <input [(ngModel)]="pipelineStep.voiceId" class="form-control" type="text" />
            </div>
          </div>

          <div class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text justify-content-between">
                  Prompt
                  <i
                    [ngbPopover]="
                      'Use the syntax {\{step:1}} to use the result of step 1. Use the syntax {\{field:test}} to use the value of the field with id test of the instance.'
                    "
                    class="bi bi-info-circle"
                    container="body"
                    triggers="hover"
                  ></i>
                </span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <textarea [(ngModel)]="pipelineStep.prompt" class="form-control" type="text"></textarea>
            </div>
          </div>

          <div *ngIf="pipelineStep.aiModel === 'ElevenLabs'" class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text justify-content-between">
                  Previous Text
                  <i
                    [ngbPopover]="
                      'Give some context for the prompt. Use the syntax {\{step:1}} to use the result of step 1. Use the syntax {\{field:test}} to use the value of the field with id test of the instance.'
                    "
                    class="bi bi-info-circle"
                    container="body"
                    triggers="hover"
                  ></i
                ></span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <textarea [(ngModel)]="pipelineStep.previousText" class="form-control" type="text"></textarea>
            </div>
          </div>

          <div *ngIf="pipelineStep.aiModel === 'ElevenLabs'" class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text justify-content-between">
                  Next Text
                  <i
                    [ngbPopover]="
                      'Give some context for the prompt. Use the syntax {\{step:1}} to use the result of step 1. Use the syntax {\{field:test}} to use the value of the field with id test of the instance.'
                    "
                    class="bi bi-info-circle"
                    container="body"
                    triggers="hover"
                  ></i
                ></span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <textarea [(ngModel)]="pipelineStep.nextText" class="form-control" type="text"></textarea>
            </div>
          </div>

          <div *ngIf="pipelineStep.aiModel === 'ElevenLabs' || pipelineStep.aiModel === 'Midjourney'" class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text justify-content-between">
                  Result filename
                  <i
                    [ngbPopover]="
                      'Use the syntax {\{step:1}} to use the result of step 1. Use the syntax {\{field:test}} to use the value of the field with id test of the instance.'
                    "
                    class="bi bi-info-circle"
                    container="body"
                    triggers="hover"
                  ></i>
                </span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <input [(ngModel)]="pipelineStep.fileName" class="form-control" type="text" />
            </div>
          </div>

          <div *ngIf="pipelineStep.aiModel === 'ElevenLabs' || pipelineStep.aiModel === 'Midjourney'" class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text justify-content-between">
                  Result file alt
                  <i
                    [ngbPopover]="
                      'Use the syntax {\{step:1}} to use the result of step 1. Use the syntax {\{field:test}} to use the value of the field with id test of the instance.'
                    "
                    class="bi bi-info-circle"
                    container="body"
                    triggers="hover"
                  ></i>
                </span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <input [(ngModel)]="pipelineStep.fileAlt" class="form-control" type="text" />
            </div>
          </div>

          <div *ngIf="pipelineStep.isLast" class="input-group mode-container">
            <div class="d-flex">
              <div class="mode-normal input-group-prepend h-100">
                <span class="input-group-text gap-1 rounded-0 text"> Field id to save picked results </span>
              </div>
            </div>

            <div class="form-control align-content-center p-0 rounded-0">
              <input [(ngModel)]="pipelineStep.saveToFieldId" class="form-control" type="text" />
            </div>
          </div>
        </div>
        <button (click)="createPipelineStep(pipelineStep.uid, i === editablePipeline.steps.length - 1)" class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i> Add step
        </button>
      </div>
    </div>
  </div>
</div>
