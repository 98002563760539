import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileEndpoints } from '@services/api';
import { GeneratedFileMeta, GeneratedTag } from '@services/types/generated';
import { AlertService } from '@services/UI-elements/alert-service';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { debounce, fuzzySearch } from '@services/utils';
import { lastValueFrom, Subscription } from 'rxjs';
import { Tag } from '@services/entities';
import { TagRepository } from '@services/repositories';
import { FileMeta } from '@services/entities/helpers/FileMeta';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrl: './file-selector.component.scss',
})
export class FileSelectorComponent implements OnInit, OnDestroy {
  @Input() fileUid: string | undefined;
  @Input({ required: true }) accept!: string; // comma-separated list of accepted mime types, extensions, or wildcards. Example: 'image/*,video/*,.pdf' or '*/*'
  @Input() typeLabel: string = 'file';
  @Output() selected = new EventEmitter<{ file?: GeneratedFileMeta }>();

  // files
  acceptedFiles: GeneratedFileMeta[] = [];
  isLoading = true;
  visibleFiles: GeneratedFileMeta[] = [];

  // file list modal
  openedFileSelectionModal: NgbModalRef | undefined;

  // search
  searchDebounced = debounce(this.search.bind(this), 250);
  searchTerm = '';

  // selection
  selectedFileMeta?: FileMeta;
  tags: Tag[] = [];
  protected readonly GeneratedTag = GeneratedTag;
  private tagSubscription?: Subscription;

  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    private fileEndpoints: FileEndpoints,
    private confirmService: ConfirmationModalService,
    private tagRepository: TagRepository,
  ) {}

  private static isFileAccepted(file: GeneratedFileMeta, accept: string) {
    const accepts = accept.split(',').map((type) => type.trim());
    if (accepts.includes('*/*')) return true; // accept all
    if (accepts.includes('*')) return true; // accept all

    const acceptedExtensions = accepts.filter((type) => type.startsWith('.')); // stuff like '.png', '.mp4'
    const acceptedTypes = accepts.filter((type) => !type.startsWith('.')); // stuff like 'image/*', 'image/png', 'video/mp4'
    const isOfAcceptedType = acceptedTypes.some((type) =>
      type.endsWith('/*') ? file.fileType.startsWith(type.split('/')[0]) : file.fileType === type,
    );
    const isOfAcceptedExtension = acceptedExtensions.some((ext) => file.name.endsWith(ext));
    return isOfAcceptedType || isOfAcceptedExtension;
  }

  async ngOnInit() {
    this.selectedFileMeta = this.fileUid
      ? await FileMeta.deserialize(await lastValueFrom(this.fileEndpoints.getFileMeta(this.fileUid)))
      : undefined;
    this.tags = await this.tagRepository.getAll();
    this.tagSubscription = this.tagRepository.cache$.subscribe((tags) => {
      this.tags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Instance).sort((a, b) => a.name.localeCompare(b.name));
    });
  }

  ngOnDestroy() {
    this.tagSubscription?.unsubscribe();
  }

  search(term?: string) {
    const query = term ?? this.searchTerm;
    if (!query.length) {
      this.visibleFiles = this.acceptedFiles;
    } else {
      this.visibleFiles = fuzzySearch(query, this.acceptedFiles, 0.75, (f) => f.name);
    }

    this.clearPopovers();
  }

  async prepareUpload() {
    await this.loadMedia(true);
  }

  async selectTag() {
    if (!this.selectedFileMeta) return;
    const formData = new FormData();
    formData.append('tags', JSON.stringify(this.selectedFileMeta.tags.map((tag) => tag.uid)));
    await lastValueFrom(this.fileEndpoints.updateFileMeta(this.selectedFileMeta.uid, formData));
  }

  openSelectFileModal(modal: TemplateRef<unknown>) {
    this.prepareUpload();

    this.modalService.dismissAll('Closed before opening new modal');
    this.openedFileSelectionModal = this.modalService.open(modal, { size: 'lg' });

    // automatically focus the input field when the modal is opened
    setTimeout(() => {
      const inputEl = document.getElementById('search-input') as HTMLInputElement;
      inputEl?.select();
    }, 0);
  }

  trackByFileId(index: number, item: GeneratedFileMeta) {
    return item.uid;
  }

  async select(file: GeneratedFileMeta) {
    this.selectedFileMeta = await FileMeta.deserialize(file);
    this.selected.next({ file: file });
    this.openedFileSelectionModal?.close();
    this.clearPopovers();
  }

  clear() {
    this.selectedFileMeta = undefined;
    this.selected.next({});
    this.openedFileSelectionModal?.close();
    this.clearPopovers();
  }

  async remove(file: GeneratedFileMeta) {
    const confirmed = await lastValueFrom(this.confirmService.confirm(`Are you sure you want to delete '${file.name}'?`));
    if (!confirmed) return;

    try {
      await lastValueFrom(this.fileEndpoints.deleteFile(file.uid));

      await this.loadMedia(false);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 409) {
        this.alertService.error('This file is being used somewhere and cannot be deleted.');
      } else {
        this.alertService.error('An error occurred: ' + (error as Error).message);
      }
    }
  }

  // NOTE: sometimes popovers keep showing when the modal is closed or when the virtual scroll changes, this is a workaround
  clearPopovers() {
    document.querySelectorAll('ngb-popover-window').forEach((popover) => {
      // Hack for now to delete the popover elements manually...
      popover.remove();
    });
  }

  private async loadMedia(showLoading: boolean) {
    let isLoading = true;
    if (showLoading) {
      setTimeout(() => {
        if (isLoading) this.isLoading = true;
      }, 500);
    }
    const allFiles = await lastValueFrom(this.fileEndpoints.getAllFileMetas()).then((f) =>
      f.files.filter((file) => !file.aiAndNotApproved),
    );
    this.acceptedFiles = allFiles.filter((file) => FileSelectorComponent.isFileAccepted(file, this.accept));

    this.search();

    isLoading = false;
    if (showLoading) this.isLoading = false;
  }
}
