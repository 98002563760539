import { Component, OnInit, TemplateRef } from '@angular/core';
import { AIPipeline, StructType } from '@services/entities';
import { Router } from '@angular/router';
import { AIPipelineRepository } from '@services/repositories/AIPipelineRepository';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { Sort } from '@angular/material/sort';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { StructTypeRepository } from '@services/repositories';

@Component({
  selector: 'app-pipeline-list',
  templateUrl: './pipeline-list.component.html',
  styleUrls: ['./pipeline-list.component.scss'],
})
export class PipelineListComponent implements OnInit {
  pipelines: AIPipeline[] = [];
  sortingPreferences: Record<string, Sort> = { Pipelines: { active: 'name', direction: '' } };
  currentSort: Sort = { active: 'name', direction: '' };
  filteredInstances: AIPipeline[] = [];
  structForPipeline = '';
  nameNewPipeline = '';
  structTypes: StructType[] = [];
  protected readonly Object = Object;

  constructor(
    private pipelineRepository: AIPipelineRepository,
    private loadingScreenService: LoadingScreenService,
    private router: Router,
    private modalService: NgbModal,
    private structTypeRepository: StructTypeRepository,
  ) {}

  async ngOnInit() {
    await this.loadingScreenService.show(async () => {
      await this.loadPipelines();
      this.sortingPreferences = JSON.parse(localStorage.getItem('sortingPreferences') ?? '{}');
      if (!this.sortingPreferences['Pipelines']) {
        this.sortingPreferences['Pipelines'] = { active: 'name', direction: '' };
      }
      this.currentSort = this.sortingPreferences['Pipelines'];
      await this.loadStructTypes();
    });
  }

  selectPipeline(pipeline: AIPipeline) {
    this.router.navigate(['home', pipeline.structTypeId, 'pipelines', pipeline.uid]).then();
  }

  openElevenlabsDictionary() {
    this.router.navigate(['home/ElevenlabsDictionary']).then();
  }

  sort(sort: Sort) {
    this.sortingPreferences['Pipelines'] = sort;
    localStorage.setItem('sortingPreferences', JSON.stringify(this.sortingPreferences));
    this.currentSort = sort;
    this.filterInstances();
  }

  filterInstances() {
    if (this.currentSort.active && this.currentSort.direction !== '') {
      this.filteredInstances = this.filteredInstances.sort((a, b) => {
        const isAsc = this.currentSort.direction === 'asc';
        switch (this.currentSort.active) {
          case 'name':
            return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
          case 'structTypeId':
            return (a.structTypeId.toLowerCase() < b.structTypeId.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
          case 'description':
            return ((a.description ?? '').toLowerCase() < (b.description ?? '').toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
          default:
            return 0;
        }
      });
    }
  }

  onOpenNewPipelineModal(content: TemplateRef<unknown>): NgbModalRef {
    this.modalService.dismissAll('Closed before opening new modal');
    this.structForPipeline = '';
    this.nameNewPipeline = '';
    return this.modalService.open(content, { size: 'lg' });
  }

  async createPipeline() {
    if (!this.structForPipeline) return;
    const newPipeline = await this.pipelineRepository.create({
      name: this.nameNewPipeline,
      uid: '_',
      structTypeId: this.structForPipeline,
      steps: [],
      isDraft: true,
    });
    this.modalService.dismissAll();
    this.router.navigate([`home/${this.structForPipeline}/pipelines/${newPipeline.uid}/edit`]).then();
  }

  private async loadPipelines() {
    this.pipelines = (await this.pipelineRepository.getAll()).filter((pipeline) => pipeline.isDraft);
  }

  private async loadStructTypes() {
    this.structTypes = await this.structTypeRepository.getAll();
  }
}
