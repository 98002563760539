import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { ConfirmationModalService } from '@services/UI-elements/confirmation-modal.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BootstrapClass } from '@services/types/BootstrapClass';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { AlertService } from '@services/UI-elements/alert-service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AIPipeline, DataInstance, StructType, Tag } from '@services/entities';
import { Field } from '@services/entities/helpers';
import { DataInstanceRepository, StructTypeRepository, TagRepository } from '@services/repositories';
import { GeneratedDataInstance, GeneratedTag } from '@services/types/generated';
import { DownloadService } from '@services/download.service';
import { isDataInstance, ListInstance } from '@services/utils/ListInstance';
import { NavigationService } from '@services/navigation.service';
import { AIPipelineRepository } from '@services/repositories/AIPipelineRepository';
import { FileEndpoints } from '@services/api';

export interface DataHeader {
  header: string;
  valuesToSkip: string;
  isKey: boolean;
  keep: boolean;
  fieldId?: string;
}

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent implements OnInit, OnDestroy {
  @ViewChild('duplicationCompleteModal') duplicationCompleteModalRef!: NgbModalRef;

  resources: DataInstance[] = [];
  allTags: Tag[] = [];
  tagsPerResource: Record<string, Tag[]> = {};
  fieldsToDisplay: Field[] = [];
  structTypeDescription?: string;
  structTypeId = '';
  structType?: StructType;
  routeSub?: Subscription;
  loading = false;
  pipelines: AIPipeline[] = [];
  importDataHeaders: DataHeader[] = [];
  fileToImport?: File;
  overwriteExisting = true;
  deleteMissing = false;

  protected newResource?: DataInstance;
  private tagSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private confirmService: ConfirmationModalService,
    private router: Router,
    private loadingScreenService: LoadingScreenService,
    private alertService: AlertService,
    protected modalService: NgbModal,
    private dataInstanceRepository: DataInstanceRepository,
    private structTypeRepository: StructTypeRepository,
    private tagRepository: TagRepository,
    private downloadService: DownloadService,
    private navigationService: NavigationService,
    private pipelineRepository: AIPipelineRepository,
    private fileEndpoints: FileEndpoints,
  ) {}

  ngOnInit() {
    return this.loadingScreenService.show(async () => {
      this.routeSub = this.route.params.subscribe((params) => {
        if (params && params[this.navigationService.queryParamKeys.ResourceStructType]) {
          this.structTypeId = params[this.navigationService.queryParamKeys.ResourceStructType];
          this.loadingScreenService.show(async () => {
            await this.loadResources().catch(() => this.router.navigate(['/home']));
            await this.loadPipelines();
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
    this.tagSubscription?.unsubscribe();
  }

  async openResource(instance: ListInstance): Promise<boolean> {
    if (isDataInstance(instance)) {
      return await this.navigationService.navigateToResource(await instance.identifier, instance.dataType);
    }
    return Promise.resolve(false);
  }

  createResource() {
    return this.loadingScreenService.show(async () => {
      const resource = await this.dataInstanceRepository.create(this.structTypeId);
      this.resources.push(resource);
      await this.openResource(resource);
    });
  }

  openModal(content: TemplateRef<NgbModalRef>) {
    this.modalService.dismissAll('Closed before opening new modal');
    this.importDataHeaders = [];
    this.fileToImport = undefined;
    this.modalService.open(content, { ariaLabelledBy: 'upload-modal-title', size: 'lg' }).result.then();
  }

  async createPipeline() {
    if (!this.structTypeId) return;
    const newPipeline = await this.pipelineRepository.create({
      name: 'New Pipeline',
      uid: '_',
      structTypeId: this.structTypeId,
      steps: [],
      isDraft: true,
    });
    this.modalService.dismissAll();
    this.router.navigate([`home/${this.structTypeId}/pipelines/${newPipeline.uid}/edit`]).then();
  }

  selectPipeline(pipeline: AIPipeline) {
    if (!this.structTypeId) return;
    this.modalService.dismissAll();
    this.router.navigate([`home/${this.structTypeId}/pipelines/${pipeline.uid}`]).then();
  }

  resourceName(instance: ListInstance) {
    if (!isDataInstance(instance)) return '';
    return instance.getName();
  }

  onFileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      this.fileToImport = file;
      this.importDataHeaders = [];
      this.overwriteExisting = false;
      this.deleteMissing = false;
      const reader = new FileReader();
      reader.onload = () => {
        const lines = (reader.result as string).split('\n');
        if (lines.length === 0) return;

        const headers = lines[0].split(',').map((header) => header.replace('\r', ''));
        if (headers.length === 0) return;

        if (!this.structType) return;
        for (const header of headers) {
          if (!this.structType.fields[header]) {
            console.warn(`Field "${header}" not found in struct type!`);
            this.importDataHeaders.push({
              header,
              valuesToSkip: '',
              isKey: false,
              keep: false,
              fieldId: undefined,
            });
          } else {
            this.importDataHeaders.push({
              header,
              valuesToSkip: '',
              isKey: false,
              keep: true,
              fieldId: this.structType.fields[header].fieldId,
            });
          }
        }
      };
      reader.readAsText(file);
    }
  }

  async importCSVData() {
    if (this.importDataHeaders.length === 0 || !this.fileToImport) return;
    await this.loadingScreenService.show(async () => {
      this.modalService.dismissAll();
      await this.fileEndpoints.importCSV(
        this.fileToImport!,
        this.importDataHeaders,
        this.structTypeId,
        this.overwriteExisting,
        this.deleteMissing,
      );
      await this.loadResources();
    });
  }

  async onTagSelected(event: { tag: Tag; instanceUid: string }) {
    const resource = this.resources.find((m) => m.randomIdentifier === event.instanceUid);
    if (!resource) return;
    if (resource.tags.find((tag) => event.tag.uid === tag.uid)) {
      resource.tags.splice(
        resource.tags.findIndex((tag) => event.tag.uid === tag.uid),
        1,
      );
    } else {
      resource.tags.push(event.tag);
      resource.tags.sort((a, b) => a.name.localeCompare(b.name));
    }
    // force angular change detection
    this.resources = [...this.resources];
  }

  async onBulkTagSelected(event: { tag: Tag; instanceUids: string[]; isAdded: boolean }) {
    const oldResources = this.resources.filter((resource) => event.instanceUids.includes(resource.randomIdentifier));
    if (oldResources.length === 0) return;

    for (const resource of oldResources) {
      if (!event.isAdded && resource.tags.find((tag) => event.tag.uid === tag.uid)) {
        resource.tags.splice(
          resource.tags.findIndex((tag) => event.tag.uid === tag.uid),
          1,
        );
      } else if (event.isAdded && !resource.tags.find((tag) => event.tag.uid === tag.uid)) {
        resource.tags.push(event.tag);
        resource.tags.sort((a, b) => a.name.localeCompare(b.name));
      }
      this.tagsPerResource[resource.randomIdentifier] = await Promise.all(resource.tags.map((tag) => Tag.deserialize(tag)));
    }

    // force angular change detection
    this.resources = [...this.resources];
  }

  deleteResource(instance: ListInstance, force = false): Promise<void> {
    if (!isDataInstance(instance)) return Promise.resolve();
    const randomIdentifier = instance.randomIdentifier;
    return this.loadingScreenService.show(async () => {
      if (!force) {
        // Open a confirmation modal
        const confirmed = await firstValueFrom(
          this.confirmService.confirm('Are you sure you want to delete resource: "' + this.resourceName(instance) + '"?'),
        );

        if (!confirmed) return;
      }

      try {
        await this.dataInstanceRepository.delete(instance, force);
        this.resources = this.resources.filter((r) => r.randomIdentifier !== randomIdentifier);
        delete this.tagsPerResource[randomIdentifier];
        // force angular change detection
        this.resources = [...this.resources];
      } catch (e) {
        if (
          !force &&
          e instanceof HttpErrorResponse &&
          e.status === 409 &&
          confirm(
            `The resource ${this.resourceName(instance)} is used in other places. Are you sure you want to delete it? This is a destructive action and cannot be undone.`,
          )
        ) {
          return this.deleteResource(instance, true);
        }

        console.error('Error deleting resource: ', e);
      }
    }) as Promise<void>;
  }

  downloadResource(instance: ListInstance) {
    if (!isDataInstance(instance)) return;

    return this.loadingScreenService.show(async () => {
      const serialized = await instance.serialize();
      const subObjects = await instance.getSubObjects();

      return this.downloadService.json((await instance.identifier) + '.json', {
        ...serialized,
        subObjects: (await Promise.all(subObjects.map(async (di) => await di.serialize()))).reduce(
          (acc, di) => {
            acc[di.uid] = di;
            return acc;
          },
          {} as Record<string, GeneratedDataInstance>,
        ),
      });
    });
  }

  duplicateResource(instance: ListInstance) {
    if (!isDataInstance(instance)) return;
    return this.loadingScreenService.show(async () => {
      try {
        const identifier = await instance.identifier;
        if (!identifier) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error('Resource not found!');
        }

        const dataInstance = (
          await this.dataInstanceRepository.duplicateDataInstances({
            instanceUids: [identifier],
            dropExternalReferencesOfTypes: [],
          })
        ).dataInstances[0];

        this.resources.push(dataInstance);
        this.tagsPerResource[dataInstance.randomIdentifier] = await Promise.all(dataInstance.tags.map((tag) => Tag.deserialize(tag)));
        this.sortResources();
        // force angular change detection
        this.resources = [...this.resources];

        this.newResource = dataInstance;

        this.modalService.dismissAll('Closed before opening new modal');
        this.modalService.open(this.duplicationCompleteModalRef, {
          ariaLabelledBy: 'duplication-success-modal',
          centered: true,
        });
      } catch (e) {
        this.alertService.showAlert('Failed to duplicate resource!', BootstrapClass.DANGER);
        throw e;
      }
    });
  }

  private sortResources() {
    this.resources.sort((a, b) => {
      const aName = this.resourceName(a) ?? '';
      const bName = this.resourceName(b) ?? '';

      // Empty strings should be sorted last
      if (!aName && !bName) return 0;
      if (!aName) return 1;
      if (!bName) return -1;
      return aName.localeCompare(bName);
    });
  }

  private async loadResources() {
    this.loading = true;

    try {
      this.structType = await this.structTypeRepository.get(this.structTypeId);
      this.fieldsToDisplay = Object.values(this.structType.fields)
        .filter((field) => field.fieldEditor && field.fieldEditor.showResource)
        .sort((a, b) => {
          if (a.fieldEditor && b.fieldEditor) return a.fieldEditor.position - b.fieldEditor.position;
          return 0;
        });

      this.resources = await this.dataInstanceRepository.getAllByStructTypeId(this.structTypeId);
      this.sortResources();

      this.allTags = await this.tagRepository.getAll();
      this.tagSubscription = this.tagRepository.cache$.subscribe((tags) => {
        this.allTags = tags.filter((tag) => tag.scope === GeneratedTag.ScopeEnum.Instance).sort((a, b) => a.name.localeCompare(b.name));
      });

      const tagDeserializationPromises = this.resources.map(async (resource) => {
        resource.tags.sort((a, b) => a.name.localeCompare(b.name));
        this.tagsPerResource[resource.randomIdentifier] = await Promise.all(resource.tags.map((tag) => Tag.deserialize(tag)));
      });
      await Promise.all(tagDeserializationPromises);

      this.structTypeDescription = this.structType.description;
    } catch (e) {
      this.alertService.error(`Failed to load resource '${this.structTypeId}'!`);
      throw e;
    } finally {
      this.loading = false;
    }
  }

  private async loadPipelines() {
    if (!this.structTypeId) return;

    this.pipelines = (await this.pipelineRepository.getAllByStructTypeId(this.structTypeId)).filter((pipeline) => pipeline.isDraft);
  }

  private parseCSVLine(line: string): string[] {
    const result: string[] = [];
    let current = '';
    let insideQuotes = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === '"') {
        // Toggle the insideQuotes flag
        if (insideQuotes && line[i + 1] === '"') {
          // Escaped quote
          current += '"';
          i++; // Skip the next quote
        } else {
          insideQuotes = !insideQuotes;
        }
      } else if (char === ',' && !insideQuotes) {
        // Field delimiter
        result.push(current);
        current = '';
      } else {
        current += char;
      }
    }
    // Push the last field
    result.push(current);
    return result;
  }
}
