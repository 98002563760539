import { Component, HostListener, OnInit } from '@angular/core';
import { AIPipelineEndpoints } from '@services/api';
import { lastValueFrom } from 'rxjs';
import { GeneratedElevenlabsDictionaryEntry } from '@services/types/generated';
import { AlertService } from '@services/UI-elements/alert-service';

@Component({
  selector: 'app-elevenlabs-dictionary',
  templateUrl: './elevenlabs-dictionary.component.html',
  styleUrls: ['./elevenlabs-dictionary.component.scss'],
})
export class ElevenlabsDictionaryComponent implements OnInit {
  dictionaryEntries: GeneratedElevenlabsDictionaryEntry[] = [];
  constructor(
    private pipelineEndpoints: AIPipelineEndpoints,
    private alertService: AlertService,
  ) {}

  @HostListener('window:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 's') {
      event.preventDefault();
      this.saveDictionary().then();
    }
  }

  async ngOnInit() {
    this.dictionaryEntries = await lastValueFrom(this.pipelineEndpoints.getElevenLabsDictionary());
  }

  addEntry() {
    this.dictionaryEntries.push({ word: '', replacement: '' });
  }

  async saveDictionary() {
    if (this.dictionaryEntries.some((entry) => entry.replacement === '' || entry.word === '')) {
      this.alertService.error('Failed to save dictionary: Some entries have empty values');
    }

    try {
      await lastValueFrom(this.pipelineEndpoints.updateElevenLabsDictionary(this.dictionaryEntries));
      this.alertService.success('Dictionary saved');
    } catch (error) {
      this.alertService.error('Failed to save dictionary: ' + error);
    }
  }

  removeEntry(entry: GeneratedElevenlabsDictionaryEntry) {
    this.dictionaryEntries = this.dictionaryEntries.filter((e) => e !== entry);
  }
}
