/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedAIPipelineStepResult } from './aIPipelineStepResult';

/**
 *
 */
export interface GeneratedAIPipelineStep {
  uid: string;
  name: string;
  modified?: string;
  previousStepUid?: string;
  aiModel: string;
  voiceId?: string;
  prompt: string;
  previousText?: string;
  nextText?: string;
  results: Array<GeneratedAIPipelineStepResult>;
  isDraft: boolean;
  isLast: boolean;
  fileName?: string;
  fileAlt?: string;
  saveToFieldId?: string;
}
