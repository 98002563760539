import { Serializable } from '@services/types/Serializable';
import { GeneratedAIPipelineStep } from '@services/types/generated';
import { AIPipelineStepResult } from '@services/entities/helpers/AIPipelineStepResult';

export interface NewAIPipelineStep {
  uid: string;
  modified: string;
  previousStepUid?: string;
  name: string;
  aiModel: string;
  voiceId?: string;
  prompt: string;
  previousText?: string;
  nextText?: string;
  results: AIPipelineStepResult[];
  isDraft: boolean;
  isLast: boolean;
  fileName?: string;
  fileAlt?: string;
  saveToFieldId?: string;
}

export class AIPipelineStep implements Serializable<GeneratedAIPipelineStep> {
  public readonly uid: string;
  public readonly modified: string;
  public previousStepUid?: string;
  public name: string;
  public aiModel: string;
  public voiceId?: string;
  public prompt: string;
  public previousText?: string;
  public nextText?: string;
  public results: AIPipelineStepResult[];
  public isDraft: boolean;
  public isLast: boolean;
  public fileName?: string;
  public fileAlt?: string;
  public saveToFieldId?: string;

  constructor({
    uid,
    modified,
    previousStepUid,
    name,
    aiModel,
    voiceId,
    prompt,
    previousText,
    nextText,
    results,
    isDraft,
    isLast,
    fileName,
    fileAlt,
    saveToFieldId,
  }: NewAIPipelineStep) {
    this.uid = uid;
    this.modified = modified;
    this.previousStepUid = previousStepUid;
    this.name = name;
    this.aiModel = aiModel;
    this.voiceId = voiceId;
    this.prompt = prompt;
    this.previousText = previousText;
    this.nextText = nextText;
    this.results = results;
    this.isDraft = isDraft;
    this.isLast = isLast;
    this.fileName = fileName;
    this.fileAlt = fileAlt;
    this.saveToFieldId = saveToFieldId;
  }

  public static async deserialize(data: GeneratedAIPipelineStep): Promise<AIPipelineStep> {
    return new AIPipelineStep({
      uid: data.uid,
      modified: data.modified ?? '',
      previousStepUid: data.previousStepUid,
      name: data.name,
      aiModel: data.aiModel,
      voiceId: data.voiceId,
      prompt: data.prompt,
      previousText: data.previousText,
      nextText: data.nextText,
      results: await Promise.all(data.results.map((result) => AIPipelineStepResult.deserialize(result))),
      isDraft: data.isDraft,
      isLast: data.isLast,
      fileName: data.fileName,
      fileAlt: data.fileAlt,
      saveToFieldId: data.saveToFieldId === '' ? undefined : data.saveToFieldId,
    });
  }

  public async serialize(): Promise<Readonly<GeneratedAIPipelineStep>> {
    return Object.freeze({
      uid: this.uid,
      modified: this.modified,
      previousStepUid: this.previousStepUid,
      name: this.name,
      aiModel: this.aiModel,
      voiceId: this.voiceId,
      prompt: this.prompt,
      previousText: this.previousText,
      nextText: this.nextText,
      results: await Promise.all(this.results.map((result) => result.serialize())),
      isDraft: this.isDraft,
      isLast: this.isLast,
      fileName: this.fileName,
      fileAlt: this.fileAlt,
      saveToFieldId: this.saveToFieldId,
    });
  }
}
