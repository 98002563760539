import { Component, OnDestroy, OnInit } from '@angular/core';
import { AIPipeline, DataInstance, StructType } from '@services/entities';
import { lastValueFrom, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AIPipelineRepository } from '@services/repositories/AIPipelineRepository';
import { DataInstanceRepository, StructTypeRepository } from '@services/repositories';
import { LoadingScreenService } from '@services/UI-elements/loading-screen.service';
import { AIPipelineStep, AIPipelineStepResult } from '@services/entities/helpers';
import { AlertService } from '@services/UI-elements/alert-service';
import { HttpErrorResponse } from '@angular/common/http';
import { FileEndpoints } from '@services/api';
import { GeneratedAIPipeline, GeneratedAIPipelineStepResult, GeneratedFileMeta } from '@services/types/generated';
import { NavigationService } from '@services/navigation.service';
import { isPipelineOverviewInstance, ListInstance } from '@services/utils/ListInstance';

export interface PipelineResult {
  result: AIPipelineStepResult;
  type: string;
  media: GeneratedFileMeta | undefined;
}

export interface PipelineOverviewInstance {
  dataInstance: DataInstance;
  results: PipelineResult[];
  pickedResult: PipelineResult | undefined;
  selected: boolean;
  loading: boolean;
  outdated: boolean;
}

@Component({
  selector: 'app-pipeline-overview',
  templateUrl: './pipeline-overview.component.html',
  styleUrls: ['./pipeline-overview.component.scss'],
})
export class PipelineOverviewComponent implements OnInit, OnDestroy {
  structTypeId?: string;
  pipelineUid?: string;
  structType?: StructType;
  pipelineInDraft?: AIPipeline;
  pipelineOverviewInstances: PipelineOverviewInstance[] = [];
  instanceRandomIdentifierMap = new Map<string, string>();
  loadedFiles: GeneratedFileMeta[] = [];
  allInstancesPicked: boolean = false;
  protected readonly Object = Object;
  private routeSub?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private pipelineRepository: AIPipelineRepository,
    private dataInstanceRepository: DataInstanceRepository,
    private loadingScreenService: LoadingScreenService,
    private router: Router,
    private alertService: AlertService,
    private fileEndpoints: FileEndpoints,
    private navigationService: NavigationService,
    private structTypeRepository: StructTypeRepository,
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe(async (params) => {
      this.structTypeId = params['structTypeId'];
      this.pipelineUid = params['pipelineUid'];
      await this.loadingScreenService.show(async () => {
        await this.loadPipelines();
      });
    });
  }

  ngOnDestroy() {
    this.routeSub?.unsubscribe();
  }

  async runPipelineFromList(instances: ListInstance[]) {
    if (instances.some((instance) => !isPipelineOverviewInstance(instance))) return;
    await this.runPipeline(instances as PipelineOverviewInstance[]);
  }

  async runPipeline(instances?: PipelineOverviewInstance[], runAllWithoutResult: boolean = false) {
    if (!this.pipelineInDraft) return;

    let runPipeline: GeneratedAIPipeline;
    let instancesToRun: PipelineOverviewInstance[] = [];
    try {
      if (instances) {
        instancesToRun = instances;
      } else if (runAllWithoutResult) {
        instancesToRun = this.pipelineOverviewInstances.filter((instance) => !instance.pickedResult);
      }
      instancesToRun.forEach((instance) => (instance.loading = true));
      runPipeline = await this.pipelineRepository.runPipeline(
        this.pipelineInDraft,
        instancesToRun.map((instance) => instance.dataInstance),
      );
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.alertService.error('Failed to run pipeline: ' + e.error.message);
      }
      console.error(e);
      this.pipelineOverviewInstances.forEach((instance) => (instance.loading = false));
      return;
    }

    const deserializedPipeline = await AIPipeline.deserialize(runPipeline);
    this.loadedFiles = await lastValueFrom(this.fileEndpoints.getAllFileMetas()).then((f) => f.files);
    await this.updatePipelineResults(deserializedPipeline);
    instancesToRun.forEach((instance) => (instance.loading = false));
  }

  async runPipelineForUndecidedInstances() {
    await this.runPipeline(undefined, true);
  }

  editPipeline() {
    if (!this.pipelineInDraft) return;
    this.router.navigate([`edit`], { relativeTo: this.route }).then();
  }

  async updateResultStatus(event: { status: GeneratedAIPipelineStepResult.StatusEnum; result: PipelineResult; instance: ListInstance }) {
    if (!isPipelineOverviewInstance(event.instance)) return;

    switch (event.status) {
      case GeneratedAIPipelineStepResult.StatusEnum.Picked:
        await this.onPickResult(event.result, event.instance);
        break;
      case GeneratedAIPipelineStepResult.StatusEnum.Undecided:
        await this.onUndecideResult(event.result, event.instance);
        break;
      case GeneratedAIPipelineStepResult.StatusEnum.Rejected:
        await this.onRejectResult(event.result, event.instance);
        break;
    }

    if (event.instance.pickedResult) {
      this.checkForOutdatedSourceValues(event.instance.pickedResult.result, event.instance);
    } else {
      event.instance.outdated = false;
    }
  }

  async onPickResult(result: PipelineResult, instance: PipelineOverviewInstance) {
    try {
      if (instance.pickedResult) {
        await this.pipelineRepository.updatePipelineStepResultStatus(
          instance.pickedResult.result.uid,
          GeneratedAIPipelineStepResult.StatusEnum.Undecided,
        );
      }
      const updatedResults = await this.pipelineRepository.updatePipelineStepResultStatus(
        result.result.uid,
        GeneratedAIPipelineStepResult.StatusEnum.Picked,
      );
      if (updatedResults.length > 1) {
        // There was a new result created to contain the previous value of the field to be able to put it back
        for (const updatedResult of updatedResults) {
          if (result.result.uid !== updatedResult.uid) {
            instance.results.push({
              result: await AIPipelineStepResult.deserialize(updatedResult),
              type: result.type,
              media: result.type !== 'Text' ? this.loadedFiles.find((file) => file.uid === updatedResult.value) : undefined,
            });
          }
        }
      }
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.alertService.error('Failed to pick result: ' + e.error.message);
      }
      console.error(e);
    }

    if (instance.pickedResult) {
      instance.pickedResult.result.status = GeneratedAIPipelineStepResult.StatusEnum.Undecided;
      instance.results.push(instance.pickedResult);
    }
    result.result.status = GeneratedAIPipelineStepResult.StatusEnum.Picked;
    instance.pickedResult = result;
    instance.results = instance.results.filter((r) => r !== result);

    if (this.pipelineOverviewInstances.every((instance) => instance.pickedResult)) {
      this.allInstancesPicked = true;
    }
  }

  async onUndecideResult(result: PipelineResult, instance: PipelineOverviewInstance) {
    result.result.status = GeneratedAIPipelineStepResult.StatusEnum.Undecided;
    if (instance.pickedResult === result) {
      instance.pickedResult = undefined;
      instance.results.push(result);
      this.allInstancesPicked = false;
    }
    await this.pipelineRepository.updatePipelineStepResultStatus(result.result.uid, GeneratedAIPipelineStepResult.StatusEnum.Undecided);
  }

  async onRejectResult(result: PipelineResult, instance: PipelineOverviewInstance) {
    result.result.status = GeneratedAIPipelineStepResult.StatusEnum.Rejected;
    if (instance.pickedResult === result) {
      instance.pickedResult = undefined;
      instance.results.push(result);
      this.allInstancesPicked = false;
    }
    await this.pipelineRepository.updatePipelineStepResultStatus(result.result.uid, GeneratedAIPipelineStepResult.StatusEnum.Rejected);
  }

  async onDownloadPickedMedia(instances: ListInstance[]) {
    if (instances.some((instance) => !isPipelineOverviewInstance(instance))) return;

    for (const instance of instances) {
      const result = (instance as PipelineOverviewInstance).pickedResult;
      if (!result || result.type === 'Text') continue;

      const file = await lastValueFrom(this.fileEndpoints.downloadFile(result.result.value));
      const url = window.URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = result.media?.name ?? 'file';
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  async openInstance(instance: ListInstance) {
    if (!this.structTypeId || !isPipelineOverviewInstance(instance)) return;
    this.navigationService.navigateToResource(await instance.dataInstance.identifier, this.structTypeId).then();
  }

  openElevenlabsDictionary() {
    this.router.navigate(['home/ElevenlabsDictionary']).then();
  }

  private async loadPipelines() {
    if (!this.structTypeId || !this.pipelineUid) return;

    this.structType = await this.structTypeRepository.get(this.structTypeId);

    this.pipelineInDraft = await this.pipelineRepository.get(this.pipelineUid);
    if (!this.pipelineInDraft) {
      console.error(`Pipeline with uid ${this.pipelineUid} not found`);
      return;
    }

    const pipelines = (await this.pipelineRepository.getAllByStructTypeId(this.structTypeId)).filter(
      (pipeline) => pipeline.basedOnPipelineUid === this.pipelineInDraft!.uid,
    );
    const dataInstances = (await this.dataInstanceRepository.getAllByStructTypeId(this.structTypeId)).sort((a, b) => {
      const indexFieldA = a.tryGetField('Index');
      const indexFieldB = b.tryGetField('Index');
      if (!indexFieldA) return -1;
      if (!indexFieldB) return 1;
      return parseInt(indexFieldA.value) - parseInt(indexFieldB.value);
    });
    for (const instance of dataInstances) {
      this.pipelineOverviewInstances.push({
        dataInstance: instance,
        results: [],
        pickedResult: undefined,
        selected: false,
        loading: false,
        outdated: false,
      });
      this.instanceRandomIdentifierMap.set(await instance.identifier, instance.randomIdentifier);
    }

    this.loadedFiles = await lastValueFrom(this.fileEndpoints.getAllFileMetas()).then((f) => f.files);

    for (const pipeline of pipelines) {
      if (pipeline.isDraft) continue;
      await this.updatePipelineResults(pipeline);
    }
    if (this.pipelineOverviewInstances.every((instance) => instance.pickedResult)) {
      this.allInstancesPicked = true;
    }
  }

  private async updatePipelineResults(pipeline: AIPipeline) {
    for (const step of pipeline.steps) {
      if (!step.isLast) continue;

      for (const result of step.results) {
        const instanceId = await result.dataInstance.identifier;
        const instanceRandomIdentifier = this.instanceRandomIdentifierMap.get(instanceId);
        const pipelineOverviewInstance = this.pipelineOverviewInstances.find(
          (instance) => instance.dataInstance.randomIdentifier === instanceRandomIdentifier,
        );
        if (!pipelineOverviewInstance) continue;

        const pipelineResult = {
          result: result,
          type: this.getResultType(step),
          media: this.getResultType(step) !== 'Text' ? this.loadedFiles.find((file) => file.uid === result.value) : undefined,
        };

        if (result.status === GeneratedAIPipelineStepResult.StatusEnum.Picked) {
          pipelineOverviewInstance.pickedResult = pipelineResult;
          this.checkForOutdatedSourceValues(result, pipelineOverviewInstance);
        } else {
          pipelineOverviewInstance.results.push(pipelineResult);
        }
      }
    }
  }

  private checkForOutdatedSourceValues(result: AIPipelineStepResult, pipelineOverviewInstance: PipelineOverviewInstance) {
    for (const sourceValue of result.sourceValues) {
      if (sourceValue.wildcard.split(':')[0] === 'field') {
        const field = sourceValue.wildcard.split(':')[1];
        const instanceField = pipelineOverviewInstance.dataInstance.tryGetField(field);
        if (!instanceField || instanceField.value !== sourceValue.value) {
          pipelineOverviewInstance.outdated = true;
          return;
        }
      }
    }
    pipelineOverviewInstance.outdated = false;
  }

  private getResultType(step: AIPipelineStep) {
    switch (step.aiModel) {
      case 'Gpt-4o':
        return 'Text';
      case 'Midjourney':
        return 'Image';
      case 'ElevenLabs':
        return 'Audio';
      default:
        return 'Text';
    }
  }
}
