import { Injectable } from '@angular/core';
import { ApiBase } from '@services/api/ApiBase';
import { Observable } from 'rxjs';
import { GeneratedAIPipeline, GeneratedAIPipelineStepResult, GeneratedElevenlabsDictionaryEntry } from '@services/types/generated';

@Injectable({ providedIn: 'root' })
export class AIPipelineEndpoints extends ApiBase {
  public getAllPipelines(): Observable<GeneratedAIPipeline[]> {
    return this.get<GeneratedAIPipeline[]>(`/game/${this.gameId}/ai-pipelines`);
  }

  public getPipeline(pipelineUid: string): Observable<GeneratedAIPipeline> {
    return this.get<GeneratedAIPipeline>(`/game/${this.gameId}/ai-pipelines/${pipelineUid}`);
  }

  public getElevenLabsDictionary(): Observable<GeneratedElevenlabsDictionaryEntry[]> {
    return this.get<GeneratedElevenlabsDictionaryEntry[]>(`/game/${this.gameId}/ai-pipelines/elevenlabs-dictionary`);
  }

  public createPipeline(pipeline: GeneratedAIPipeline): Observable<GeneratedAIPipeline> {
    return this.post<GeneratedAIPipeline>(`/game/${this.gameId}/ai-pipelines`, pipeline);
  }

  public runPipeline(pipelineUid: string, instanceIds: string[]): Observable<GeneratedAIPipeline> {
    return this.post<GeneratedAIPipeline>(`/game/${this.gameId}/ai-pipelines/${pipelineUid}/${this.dataPackage}/run`, instanceIds);
  }

  public updatePipeline(pipelineUid: string, pipeline: GeneratedAIPipeline): Observable<GeneratedAIPipeline> {
    return this.put<GeneratedAIPipeline>(`/game/${this.gameId}/ai-pipelines/${pipelineUid}`, pipeline);
  }

  public updatePipelineStepResultStatus(resultUid: string, status: string): Observable<GeneratedAIPipelineStepResult[]> {
    return this.put(`/game/${this.gameId}/ai-pipelines/result/${resultUid}`, status);
  }

  public updateElevenLabsDictionary(dictionary: GeneratedElevenlabsDictionaryEntry[]): Observable<GeneratedElevenlabsDictionaryEntry[]> {
    return this.put(`/game/${this.gameId}/ai-pipelines/elevenlabs-dictionary`, dictionary);
  }

  public deletePipeline(pipelineUid: string): Observable<void> {
    return this.delete<void>(`/game/${this.gameId}/ai-pipelines/${pipelineUid}`);
  }
}
