<div class="modal-header">
  <h5 class="modal-title">Select pipeline</h5>
  <button (click)="close()" aria-label="Close" class="btn-close" type="button"></button>
</div>
<div class="modal-body">
  <table *ngIf="pipelines.length > 0" class="w-100">
    <thead>
      <tr>
        <th>Name</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let pipeline of pipelines">
        <td>{{ pipeline.name }}</td>
        <td>
          <button (click)="selectPipeline(pipeline)" class="btn btn-primary">Select</button>
        </td>
      </tr>
    </tbody>
  </table>
  <button (click)="createPipeline()" class="btn btn-primary" title="Create pipeline">Create pipeline</button>
</div>
