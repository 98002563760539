/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedAIPipelineStepResultSourceValue } from './aIPipelineStepResultSourceValue';
import { GeneratedDataInstance } from './dataInstance';

/**
 *
 */
export interface GeneratedAIPipelineStepResult {
  uid: string;
  value: string;
  status: GeneratedAIPipelineStepResult.StatusEnum;
  dataInstance: GeneratedDataInstance;
  pipelineRunUid: string;
  sourceValues: Array<GeneratedAIPipelineStepResultSourceValue>;
}
export namespace GeneratedAIPipelineStepResult {
  export type StatusEnum = 'UNDECIDED' | 'PICKED' | 'LIKED' | 'REJECTED';
  export const StatusEnum = {
    Undecided: 'UNDECIDED' as StatusEnum,
    Picked: 'PICKED' as StatusEnum,
    Liked: 'LIKED' as StatusEnum,
    Rejected: 'REJECTED' as StatusEnum,
  };
}
