<div class="justify-content-center w-100 d-flex align-items-center gap-2 mt-2">
  <h1>ElevenLabs dictionary</h1>
  <button (click)="addEntry()" class="btn btn-primary h-fit" title="Add entry"><i class="bi bi-plus"></i></button>
  <button (click)="saveDictionary()" class="btn btn-primary" title="Save dictionary"><i class="bi bi-save"></i></button>
</div>
<div class="p-4 d-flex justify-content-center">
  <div class="col-6 flex-column border-end border-4 me-2 pe-2">
    <p class="d-flex justify-content-end"><b>Word</b></p>
    <div *ngFor="let entry of dictionaryEntries; let i = index" class="d-flex justify-content-end">
      <input [(ngModel)]="entry.word" class="form-control w-50" [tabindex]="i * 2" />
    </div>
  </div>
  <div class="col-6 flex-column">
    <p><b>Replacement</b></p>
    <div *ngFor="let entry of dictionaryEntries; let i = index" class="d-flex gap-2">
      <input [(ngModel)]="entry.replacement" class="form-control w-50" [tabindex]="i * 2 + 1" />
      <button (click)="removeEntry(entry)" class="btn btn-danger btn-sm" title="Remove entry"><i class="bi bi-x"></i></button>
    </div>
  </div>
</div>
