import { GeneratedFileMeta } from '@services/types/generated';
import { PipelineOverviewInstance } from '../../_components/AI-pipelines/pipeline-overview/pipeline-overview.component';
import { DataInstance, EnumType, SelectType, StructType, Variable } from '@services/entities';
import { DataModelListInstance } from '../../_components/schema-editor/schema-list/schema-list.component';

export type ListInstance = GeneratedFileMeta | DataInstance | Variable | DataModelListInstance | PipelineOverviewInstance;

export function isDataInstance(instance: ListInstance): instance is DataInstance {
  return instance instanceof DataInstance;
}

export function isVariable(instance: ListInstance): instance is Variable {
  return instance instanceof Variable;
}

export function isGeneratedFileMeta(instance: ListInstance): instance is GeneratedFileMeta {
  return 'name' in instance && 'alt' in instance;
}

export function isDataModelListInstance(instance: ListInstance): instance is DataModelListInstance {
  return instance instanceof StructType || instance instanceof EnumType || instance instanceof SelectType;
}

export function isPipelineOverviewInstance(instance: ListInstance): instance is PipelineOverviewInstance {
  return 'results' in instance && 'pickedResult' in instance;
}

export function getNameOfListInstance(instance: ListInstance): string {
  if (isDataInstance(instance)) {
    return instance.getName();
  } else if (isPipelineOverviewInstance(instance)) {
    return instance.dataInstance.getName();
  }
  return instance.name;
}

export function getUidOfListInstance(instance: ListInstance): string {
  if (isDataInstance(instance)) {
    return instance.randomIdentifier;
  } else if (isVariable(instance)) {
    return instance.variableRef;
  } else if (isDataModelListInstance(instance)) {
    return instance.typeId;
  } else if (isPipelineOverviewInstance(instance)) {
    return instance.dataInstance.randomIdentifier;
  }
  return instance.uid;
}
