import { Injectable } from '@angular/core';
import { AIPipeline } from '@services/entities/AIPipeline';
import { AIPipelineEndpoints } from '@services/api/AIPipelineEndpoints';
import { DirtyHandling } from '@services/decorators/DirtyHandling';
import { lastValueFrom, map } from 'rxjs';
import { Repository } from '@services/repositories/Repository';
import { Cache } from '@services/utils';
import { GeneratedAIPipeline, GeneratedAIPipelineStepResult } from '@services/types/generated';
import { DataInstance } from '@services/entities';

@Injectable({ providedIn: 'root' })
export class AIPipelineRepository extends Repository<AIPipeline> {
  private readonly cache = new Cache<AIPipeline>();

  constructor(private pipelineEndpoints: AIPipelineEndpoints) {
    super();
  }

  @DirtyHandling()
  public override async save(entity: AIPipeline): Promise<void> {
    const updatedPipeline = await lastValueFrom(this.pipelineEndpoints.updatePipeline(entity.uid, await entity.serialize()));
    this.cache.set(entity.uid, await AIPipeline.deserialize(updatedPipeline), 15);
  }

  public override async delete(entity: AIPipeline): Promise<void> {
    await lastValueFrom(this.pipelineEndpoints.deletePipeline(entity.uid));
    this.cache.invalidate(entity.uid);
  }

  public override async create(data: GeneratedAIPipeline): Promise<AIPipeline> {
    return this.cache.set(data.uid, await AIPipeline.deserialize(await lastValueFrom(this.pipelineEndpoints.createPipeline(data))), 15);
  }

  public async getAll(): Promise<AIPipeline[]> {
    return await Promise.all(
      (await lastValueFrom(this.pipelineEndpoints.getAllPipelines())).map(async (pipeline) => {
        if (this.cache.isValid(pipeline.uid)) return this.cache.get(pipeline.uid)!.value;
        return this.cache.set(pipeline.uid, await AIPipeline.deserialize(pipeline), 15);
      }),
    );
  }

  public override async get(id: string, skipCache: boolean = false): Promise<AIPipeline> {
    if (!skipCache && this.cache.isValid(id)) {
      return this.cache.get(id)!.value;
    }

    this.requests[id] = this.pipelineEndpoints
      .getPipeline(id)
      .pipe(map(async (response) => this.cache.set(id, await AIPipeline.deserialize(response), 15)));

    const data = await lastValueFrom(this.requests[id]);
    delete this.requests[id];
    return data;
  }

  public async getAllByStructTypeId(structTypeId: string): Promise<AIPipeline[]> {
    return (await this.getAll()).filter((pipeline) => pipeline.structTypeId === structTypeId);
  }

  public async runPipeline(pipeline: AIPipeline, instances: DataInstance[]) {
    const dataInstanceIds = await Promise.all(instances.map(async (instance) => await instance.identifier));
    const runPipeline = await lastValueFrom(this.pipelineEndpoints.runPipeline(pipeline.uid, dataInstanceIds));
    this.cache.set(runPipeline.uid, await AIPipeline.deserialize(runPipeline), 15);
    return runPipeline;
  }

  public async updatePipelineStepResultStatus(resultId: string, status: GeneratedAIPipelineStepResult.StatusEnum) {
    const result = await lastValueFrom(this.pipelineEndpoints.updatePipelineStepResultStatus(resultId, status));
    this.cache.invalidate();
    return result;
  }
}
