import { GeneratedAIPipelineStepResult } from '@services/types/generated';
import { DataInstance } from '@services/entities';
import { AIPipelineStepResultSourceValue } from '@services/entities/helpers/AIPipelineStepResultSourceValue';

export interface NewAIPipelineStepResult {
  uid: string;
  value: string;
  status: GeneratedAIPipelineStepResult.StatusEnum;
  dataInstance: DataInstance;
  pipelineRunUid: string;
  sourceValues: AIPipelineStepResultSourceValue[];
}

export class AIPipelineStepResult {
  public readonly uid: string;
  public value: string;
  public status: GeneratedAIPipelineStepResult.StatusEnum;
  public dataInstance: DataInstance;
  public pipelineRunUid: string;
  public sourceValues: AIPipelineStepResultSourceValue[];

  constructor({ uid, value, status, dataInstance, pipelineRunUid, sourceValues }: NewAIPipelineStepResult) {
    this.uid = uid;
    this.value = value;
    this.status = status;
    this.dataInstance = dataInstance;
    this.pipelineRunUid = pipelineRunUid;
    this.sourceValues = sourceValues;
  }

  public static async deserialize(data: GeneratedAIPipelineStepResult): Promise<AIPipelineStepResult> {
    return new AIPipelineStepResult({
      uid: data.uid,
      value: data.value,
      status: data.status,
      dataInstance: await DataInstance.deserialize(data.dataInstance),
      pipelineRunUid: data.pipelineRunUid,
      sourceValues: await Promise.all(data.sourceValues.map((sv) => AIPipelineStepResultSourceValue.deserialize(sv))),
    });
  }

  public async serialize(): Promise<Readonly<GeneratedAIPipelineStepResult>> {
    return Object.freeze({
      uid: this.uid,
      value: this.value,
      status: this.status,
      dataInstance: await this.dataInstance.serialize(),
      pipelineRunUid: this.pipelineRunUid,
      sourceValues: await Promise.all(this.sourceValues.map((sv) => sv.serialize())),
    });
  }
}
