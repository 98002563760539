/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GeneratedAIPipelineStep } from './aIPipelineStep';

/**
 *
 */
export interface GeneratedAIPipeline {
  uid: string;
  name: string;
  description?: string;
  modified?: string;
  structTypeId: string;
  basedOnPipelineUid?: string;
  steps: Array<GeneratedAIPipelineStep>;
  isDraft: boolean;
}
