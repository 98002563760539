import { Entity } from '@services/entities/Entity';
import { AutoSave } from '@services/decorators/AutoSave';
import GTInjector from '@services/GTInjector';
import { AIPipelineRepository } from '@services/repositories/AIPipelineRepository';
import { GeneratedAIPipeline } from '@services/types/generated';
import { AIPipelineStep } from '@services/entities/helpers/AIPipelineStep';

export interface NewAIPipeline {
  uid: string;
  name: string;
  description?: string;
  modified: string;
  structTypeId: string;
  steps: AIPipelineStep[];
  isDraft: boolean;
  basedOnPipelineUid?: string;
}

@AutoSave()
export class AIPipeline extends Entity {
  public readonly uid: string;
  public name: string;
  public description?: string;
  public readonly modified: string;
  public structTypeId: string;
  public steps: AIPipelineStep[] = [];
  public isDraft: boolean;
  public basedOnPipelineUid?: string;

  constructor({ uid, name, description, modified, structTypeId, steps, isDraft, basedOnPipelineUid }: NewAIPipeline) {
    super();
    this.uid = uid;
    this.name = name;
    this.description = description;
    this.modified = modified;
    this.structTypeId = structTypeId;
    this.steps = steps;
    this.isDraft = isDraft;
    this.basedOnPipelineUid = basedOnPipelineUid;
  }

  public override get identifier() {
    return this.uid;
  }

  public static async deserialize(data: GeneratedAIPipeline): Promise<AIPipeline> {
    const pipeline = new AIPipeline({
      uid: data.uid,
      name: data.name,
      description: data.description,
      modified: data.modified ?? '',
      structTypeId: data.structTypeId,
      steps: await Promise.all(data.steps.map((step) => AIPipelineStep.deserialize(step))),
      isDraft: data.isDraft,
      basedOnPipelineUid: data.basedOnPipelineUid,
    });

    await pipeline.updateLastSavedValueHash();
    return pipeline;
  }

  public async save(): Promise<void> {
    return (await GTInjector.inject(AIPipelineRepository)).save(this);
  }

  public async serialize(): Promise<GeneratedAIPipeline> {
    return Object.freeze({
      uid: this.uid,
      name: this.name,
      description: this.description,
      modified: this.modified,
      structTypeId: this.structTypeId,
      steps: await Promise.all(this.steps.map((step) => step.serialize())),
      isDraft: this.isDraft,
      basedOnPipelineUid: this.basedOnPipelineUid,
    });
  }
}
